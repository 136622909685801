import { DataType } from '@/util/dataType'
import CryptoJS from 'crypto-js'

class Tool {
  static _key = key => 'sz' + key

  getItem = (key) => localStorage.getItem(Tool._key(key))

  setItem = (key, value) => localStorage.setItem(Tool._key(key), value)

  delItem = (key) => localStorage.removeItem(Tool._key(key))

  clearItem = () => localStorage.clear()

  isPC () {
    const userAgentInfo = navigator.userAgent
    const Agents = ['Android', 'iPhone',
      'SymbianOS', 'Windows Phone',
      'iPad', 'iPod']
    let flag = true
    for (let v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) > 0) {
        flag = false
        break
      }
    }
    return flag
  }

  getQueryVariable (variable) {
    const query = window.location.href.split('?')[1]
    const vars = query.split('&')
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=')
      if (pair[0] === variable) {
        return pair[1]
      }
    }
    return (false)
  }

  md5 (source) {
    const hash = CryptoJS.MD5(source)
    return hash.toString()
  }

  getRandom (size) {
    let random = ''
    for (let i = 0; i < size; i++) {
      random += Math.floor(Math.random() * 10).toString()
    }
    return random
  }

  getEnumTextByValue (e, value) {
    let text = ''

    for (const item in e) {
      // eslint-disable-next-line eqeqeq
      if (e[item].value == value) {
        text = e[item].text
      }
    }

    return text
  }

  isNull (value) {
    if (value === undefined || value === null) {
      return true
    } else {
      return false
    }
  }

  isNotNull (value) {
    if (value !== undefined && value !== null) {
      return true
    } else {
      return false
    }
  }

  isNotEmpty (value) {
    return (
      value !== undefined &&
      value !== '' &&
      value !== null &&
      value !== 'N/A' &&
      JSON.stringify(value) !== '{}' &&
      JSON.stringify(value) !== '[]'
    )
  }

  isEmpty (value) {
    return (
      value === undefined ||
      value === null ||
      value === 'N/A' ||
      value === '' ||
      JSON.stringify(value) === '{}' ||
      JSON.stringify(value) === '[]'
    )
  }

  formatCurrency (num) {
    if (this.isNotEmpty(num)) {
      if (Number(num) === 0) {
        return '$0.00'
      }
      if (num) {
        num = Number(num).toFixed(2)
        // 将num中的$,去掉，将num变成一个纯粹的数据格式字符串
        // eslint-disable-next-line no-useless-escape
        num = num.toString().replace(/\$|\,/g, '')
        // 如果num不是数字，则将num置0，并返回
        if (num === '' || isNaN(num)) {
          return 'Not a Number ! '
        }
        // 如果num是负数，则获取她的符号
        const sign = num.indexOf('-') > -1 ? '-' : ''
        let cents = num.indexOf('.') > -1 ? num.substr(num.indexOf('.')) : '.00'
        for (let i = 0; i < 3 - cents.length; ++i) {
          cents = cents + '0'
        }

        let numStart = 0
        if (sign === '-') {
          numStart = 1
        }
        num = num.indexOf('.') > -1 ? num.substring(numStart, num.indexOf('.')) : num.substr(numStart)
        /*
          也可以这样想象，现在有一串数字字符串在你面前，如果让你给他家千分位的逗号的话，你是怎么来思考和操作的?
          字符串长度为0/1/2/3时都不用添加
          字符串长度大于3的时候，从右往左数，有三位字符就加一个逗号，然后继续往前数，直到不到往前数少于三位字符为止
         */
        for (let i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++) {
          num = num.substring(0, num.length - (4 * i + 3)) + ',' + num.substring(num.length - (4 * i + 3))
        }

        if (num.length > 0 && num.substr(0, 1) === ',') {
          num.slice(0)
        }

        // 将数据（符号、整数部分、小数部分）整体组合返回
        if (sign === '-') {
          return '($' + num + cents + ')'
        } else {
          return '$' + num + cents
        }
      }
    } else {
      return ''
    }
  }

  formatCurrencyInt (num) {
    if (this.isNotEmpty(num)) {
      if (Number(num) === 0) {
        return '$0'
      }
      if (num) {
        num = Number(num).toFixed(2)
        // 将num中的$,去掉，将num变成一个纯粹的数据格式字符串
        // eslint-disable-next-line no-useless-escape
        num = num.toString().replace(/\$|\,/g, '')
        // 如果num不是数字，则将num置0，并返回
        if (num === '' || isNaN(num)) {
          return 'Not a Number ! '
        }
        // 如果num是负数，则获取她的符号
        const sign = num.indexOf('-') > -1 ? '-' : ''

        let numStart = 0
        if (sign === '-') {
          numStart = 1
        }
        num = num.indexOf('.') > -1 ? num.substring(numStart, num.indexOf('.')) : num.substr(numStart)
        /*
          也可以这样想象，现在有一串数字字符串在你面前，如果让你给他家千分位的逗号的话，你是怎么来思考和操作的?
          字符串长度为0/1/2/3时都不用添加
          字符串长度大于3的时候，从右往左数，有三位字符就加一个逗号，然后继续往前数，直到不到往前数少于三位字符为止
         */
        for (let i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++) {
          num = num.substring(0, num.length - (4 * i + 3)) + ',' + num.substring(num.length - (4 * i + 3))
        }

        if (num.length > 0 && num.substr(0, 1) === ',') {
          num.slice(0)
        }

        // 将数据（符号、整数部分、小数部分）整体组合返回
        if (sign === '-') {
          return '($' + num + ')'
        } else {
          return '$' + num
        }
      }
    } else {
      return ''
    }
  }

  formatDate (timestamp) {
    let result = null
    try {
      if (typeof timestamp === 'string') {
        timestamp = parseInt(timestamp, 10)
      }

      if (this.isNotEmpty(timestamp)) {
        const time = new Date(timestamp)
        const year = time.getUTCFullYear()
        const month = time.getUTCMonth() + 1
        const day = time.getUTCDate()

        result = year + '-'

        if (month < 10) {
          result += '0' + month + '-'
        } else {
          result += month + '-'
        }

        if (day < 10) {
          result += '0' + day
        } else {
          result += day
        }
      }

      return result
    } catch (e) {
      return result
    }
  }

  formatDateTime (timestamp) {
    if (typeof timestamp === 'string') {
      timestamp = parseInt(timestamp, 10)
    }

    if (this.isNotEmpty(timestamp)) {
      const date = new Date(timestamp)

      return date.format('yyyy-MM-dd HH:mm:ss')
    } else {
      return '-- ::'
    }
  }

  dateFormat (date, param) {
    if (typeof date === 'object') {
      return date.format(param)
    }
    return date
  }

  formatPhone (phone) {
    if (this.isNotEmpty(phone)) {
      try {
        phone = phone.replace(/[^0-9]/gi, '')
        return '(' + phone.substring(0, 3) + ')' + phone.substring(3, 6) + '-' + phone.substring(6, phone.length)
      } catch (e) {
        return null
      }
    } else {
      return null
    }
  }

  formatSsn (ssn) {
    if (this.isNotEmpty(ssn)) {
      try {
        ssn = ssn.replace(/[^0-9]/gi, '')
        return ssn.substring(0, 3) + '-' + ssn.substring(3, 5) + '-' + ssn.substring(5, ssn.length)
      } catch (e) {
        return null
      }
    } else {
      return null
    }
  }

  formatPercent (num, digits) {
    const floatNum = parseFloat(num)
    if (this.isEmpty(digits)) {
      digits = 2
    }
    const str = Number(floatNum * 100).toFixed(digits)
    return str + '%'
  }

  properCase (value) {
    let separator
    if (value.includes(', ')) separator = ', '
    else separator = ' '

    const array = value.toUpperCase().split(separator)
    const newArray = []

    for (let i = 0; i < array.length; i++) {
      if (array[i].includes(' ')) {
        newArray.push(this.properCase(array[i]))
      } else {
        if (array[i].length <= 2) {
          newArray.push(array[i])
        } else {
          if (array[i].includes('/')) newArray.push(array[i])
          else newArray.push(array[i][0] + array[i].substr(1).toLowerCase())
        }
      }
    }

    return newArray.join(separator)
  }

  compareArr (key) {
    return function (value1, value2) {
      const val1 = value1[key]
      const val2 = value2[key]
      return val1 - val2
    }
  }

  array2json (array, fieldKey, valueKey) {
    const result = {}
    if (this.isNotEmpty(array)) {
      for (let i = 0; i < array.length; i++) {
        const dataJson = array[i]
        if (Object.prototype.hasOwnProperty.call(dataJson, fieldKey)) {
          const key = dataJson[fieldKey]
          let value = null
          if (Object.prototype.hasOwnProperty.call(dataJson, valueKey)) {
            value = dataJson[valueKey]
          }
          result[key] = value
        }
      }
    }
    return result
  }

  reviewDataJson2Array (reviewDataSource, editReview, auditReview, applicationId, sectionKey) {
    const reviewDataArray = []
    // eslint-disable-next-line guard-for-in
    const updateData = {}
    // eslint-disable-next-line guard-for-in
    for (const key in editReview) {
      if (editReview[key] === false && auditReview[key] === false) {
        updateData[key] = 101
      } else if (editReview[key] === true && auditReview[key] === false) {
        updateData[key] = 102
      } else if (editReview[key] === false && auditReview[key] === true) {
        updateData[key] = 103
      } else {
        updateData[key] = 101
      }
      const reviewRow = {
        applicationId: applicationId,
        sectionKey: sectionKey,
        fieldKey: key,
        reviewStatus: updateData[key],
        reviewStatusText: updateData[key].toString()
      }
      for (let i = 0; i < reviewDataSource.length; i++) {
        if (key === reviewDataSource[i].fieldKey) {
          reviewRow.id = reviewDataSource[i].id
        }
      }
      reviewDataArray.push(reviewRow)
    }
    return reviewDataArray
  }

  answer2array (applicationId, answerData) {
    const applicationQuestionDataArray = []
    for (const key in answerData) {
      if (answerData[key].question !== undefined && answerData[key].answer !== undefined) {
        let applicationQuestionData = {}
        if (answerData[key].id === undefined) {
          applicationQuestionData = {
            applicationId: applicationId,
            question: answerData[key].question,
            answer: answerData[key].answer
          }
        } else {
          applicationQuestionData = {
            id: answerData[key].id,
            applicationId: applicationId,
            question: answerData[key].question,
            answer: answerData[key].answer
          }
        }
        applicationQuestionDataArray.push(applicationQuestionData)
      }
    }
    return applicationQuestionDataArray
  }

  setCookie (name, value) {
    const expires = new Date()
    expires.setTime(expires.getTime() + 24 * 60 * 60 * 1000)
    document.cookie = '{0}={1};expires={2};path=/'.format(name, escape(value), expires.toUTCString())
  }

  getCookie (name) {
    const reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)')
    const arr = document.cookie.match(reg)
    if (arr) {
      return unescape(arr[2])
    } else {
      return null
    }
  }

  deleteCookie (name) {
    const exp = new Date()
    exp.setTime(exp.getTime() - 1)
    const cval = this.getCookie(name)
    if (cval != null) {
      document.cookie = '{0}={1};expires={2};path=/'.format(name, escape(cval), exp.toGMTString())
    }
  }

  getApplicationStatus (sourceValue) {
    let result = ''

    for (const item in DataType.LoanStatus) {
      // eslint-disable-next-line eqeqeq
      if ((sourceValue | DataType.LoanStatus[item].value) == sourceValue) {
        result += DataType.LoanStatus[item].text + ' '
      }
    }

    return result
  }

  isCurrencyKey (key) {
    return [
      DataType.JsonKeyConst.PRINCIPAL,
      DataType.JsonKeyConst.INTEREST,
      DataType.JsonKeyConst.NSF_FEE,
      DataType.JsonKeyConst.LATE_FEE,
      DataType.JsonKeyConst.APPROVED_AMOUNT,
      DataType.JsonKeyConst.LOAN_SIZE_AMOUNT,
      DataType.JsonKeyConst.AMOUNT_OF_OPEN_LOANS,
      DataType.JsonKeyConst.BANK_ACCOUNT_BALANCE,
      DataType.JsonKeyConst.TOTAL_WAIVED_AMOUNT,
      DataType.JsonKeyConst.TOTAL_WAIVED_LATE_FEE,
      DataType.JsonKeyConst.TOTAL_WAIVED_NSF_FEE,
      DataType.JsonKeyConst.TOTAL_WAIVED_PRINCIPAL,
      DataType.JsonKeyConst.TOTAL_WAIVED_INTEREST,
      DataType.JsonKeyConst.MONTHLY_INCOME,
      DataType.JsonKeyConst.LOAN_AMOUNT,
      DataType.JsonKeyConst.TOTAL_AMOUNT,
      DataType.JsonKeyConst.ACCOUNT_BALANCE
    ].includes(key)
  }

  isDateKey (key) {
    return [
      DataType.JsonKeyConst.EFFECTIVE_DATE,
      DataType.JsonKeyConst.CREDIT_DATE,
      DataType.JsonKeyConst.BIRTHDAY,
      DataType.JsonKeyConst.LAST_PAYDAY,
      DataType.JsonKeyConst.TRANSACTION_DATE,
      DataType.JsonKeyConst.CONTACT_START_TIME,
      DataType.JsonKeyConst.CONTACT_STOP_TIME,
      DataType.JsonKeyConst.SHIFT_START_TIME,
      DataType.JsonKeyConst.SHIFT_STOP_TIME,
      DataType.JsonKeyConst.LOCKED_AT,
      DataType.JsonKeyConst.CREATE_FILE_DATE
    ].includes(key)
  }

  isDateTimeKey (key) {
    return [
      DataType.JsonKeyConst.FOLLOW_UP_AT,
      DataType.JsonKeyConst.SENT_AT,
      DataType.JsonKeyConst.SIGNED_AT,
      DataType.JsonKeyConst.UPDATED_AT,
      DataType.JsonKeyConst.CREATED_AT
    ].includes(key)
  }

  isIntervalKey (key) {
    return [DataType.JsonKeyConst.SSN].includes(key)
  }

  toIntervalString (value) {
    if (value.indexOf('-') === -1) {
      value = value.substring(0, 3) + '-' + value.substring(3, 5) + '-' + value.substring(5, value.length)
    }
    return value
  }

  isPhoneOrFaxKey (key) {
    return [
      DataType.JsonKeyConst.HOME_PHONE,
      DataType.JsonKeyConst.CELL_PHONE,
      DataType.JsonKeyConst.EMPLOYER_PHONE,
      DataType.JsonKeyConst.BANK_PHONE
    ].includes(key)
  }

  isToPercent (key) {
    return [
      DataType.JsonKeyConst.INTEREST_RATE,
      DataType.JsonKeyConst.APR
    ].includes(key)
  }

  adapterKey (key) {
    let result = ''

    if (this.isNotEmpty(key)) {
      const keyArray = key
        .replace(/[A-Z]/g, function (letter) {
          return ' ' + letter
        })
        .split(' ')
      for (const item of keyArray) {
        result += item.slice(0, 1).toUpperCase() + item.slice(1) + ' '
      }
      result = result.trim()

      switch (key) {
        case DataType.JsonKeyConst.CHANGE_TYPE:
          return 'Function Name'
        case DataType.JsonKeyConst.LAST_PAYDAY:
          return 'Last Payroll Date'
        case DataType.JsonKeyConst.TRANSACTION_MODE:
          return 'Payment Mode'
        case DataType.JsonKeyConst.TRANSACTION_DATE:
          return 'Payment Date'
        case DataType.JsonKeyConst.FOLLOWUP_AT:
          return 'Follow Up At'
        case DataType.JsonKeyConst.FOLLOWUP_BY:
          return 'Follow Up By'
        case DataType.JsonKeyConst.BANK_ACCOUNT_BALANCE:
          return 'Account Balance'
        case DataType.JsonKeyConst.DOCUMENT_URL:
          return 'Document'
        case DataType.JsonKeyConst.CERTIFICATE_URL:
          return 'Certificate'
        case DataType.JsonKeyConst.LOAN_AGREEMENT_URL:
          return 'Loan Agreement'
        case DataType.JsonKeyConst.CONTACT_START_TIME:
          return 'Contact Client Start Time'
        case DataType.JsonKeyConst.CONTACT_STOP_TIME:
          return 'Contact Client Finish Time'
        case DataType.JsonKeyConst.SHIFT_START_TIME:
          return 'Work Shift Start Time'
        case DataType.JsonKeyConst.SHIFT_STOP_TIME:
          return 'Work Shift Stop Time'
        case DataType.JsonKeyConst.WITHDRAW_CODE:
          return 'Withdraw Reason'
        case DataType.JsonKeyConst.CATEGORY:
          return 'Customer Type'
        case DataType.JsonKeyConst.VOE:
          return 'VOE'
      }
    }

    return result
  }

  // eslint-disable-next-line complexity
  adapterValue (key, value) {
    if (this.isEmpty(value)) {
      return ''
    } else if (this.isCurrencyKey(key)) {
      return this.formatCurrency(value)
    } else if (this.isDateKey(key)) {
      return this.formatDate(value)
    } else if (this.isDateTimeKey(key)) {
      return this.formatDateTime(value)
    } else if (this.isIntervalKey(key)) {
      return this.toIntervalString(value)
    } else if (this.isPhoneOrFaxKey(key)) {
      return this.formatPhone(value)
    } else if (this.isToPercent(key)) {
      return this.formatPercent(value)
    } else if (key.indexOf('Verify') >= 0) {
      return this.getEnumTextByValue(DataType.ReviewStatusEnum, value)
    } else {
      switch (key) {
        case DataType.JsonKeyConst.CHANGE_TYPE:
          return this.getEnumTextByValue(DataType.ChangeTypeEnum, value)
        case DataType.JsonKeyConst.PAYROLL_FREQUENCY:
          return this.getEnumTextByValue(DataType.PayrollFrequency, value)
        case DataType.JsonKeyConst.TRANSACTION_MODE:
          return this.getEnumTextByValue(DataType.TransactionModeEnum, value)
        case DataType.JsonKeyConst.ACCOUNT_TYPE:
          return this.getEnumTextByValue(DataType.BankAccountType, value)
        case DataType.JsonKeyConst.SIGN_STATUS:
          return this.getEnumTextByValue(DataType.SignStatus, value)
        case DataType.JsonKeyConst.PAYDAY_ON_HOLIDAY:
          return this.getEnumTextByValue(DataType.PaydayOnHolidayType, value)
        case DataType.JsonKeyConst.FIRST_DAY_OF_WEEK:
          return this.getEnumTextByValue(DataType.FirstDayOfWeek, value)
        case DataType.JsonKeyConst.APPLICATION_STATUS:
          return this.getApplicationStatus(value)
        case DataType.JsonKeyConst.TRANSACTION_STATUS:
          return this.getEnumTextByValue(DataType.TransactionStatusEnum, value)
        case DataType.JsonKeyConst.GENDER:
          return this.getEnumTextByValue(DataType.Gender, value)
        case DataType.JsonKeyConst.PAYROLL_TYPE:
          return this.getEnumTextByValue(DataType.PayrollTypeEnum, value)
        case DataType.JsonKeyConst.MILITARY:
          return this.getEnumTextByValue(DataType.BooleanInBinary, value)
        case DataType.JsonKeyConst.WITHDRAW_CODE:
          return this.getEnumTextByValue(DataType.WithdrawCodeEnum, value)
        case DataType.JsonKeyConst.DOCUMENT_TYPE:
          return this.getEnumTextByValue(DataType.DocumentTypeEnum, value)
        case DataType.JsonKeyConst.PAYDAY_ON_AVAILABLE:
          return this.getEnumTextByValue(DataType.PaydayOnAvailableEnum, value)
        case DataType.JsonKeyConst.VOE:
          return this.getEnumTextByValue(DataType.VoE, value)
        case DataType.JsonKeyConst.CATEGORY:
          return this.getEnumTextByValue(DataType.CategoryEnum, value)
        case DataType.JsonKeyConst.LOCK_REASON:
          return this.getEnumTextByValue(DataType.LockApplicationReasonEnum, value)
        case DataType.JsonKeyConst.FIRST_DAY_OF_MONTH:
          return this.getEnumTextByValue(DataType.TotalDayOfMonthEnum, value)
        case DataType.JsonKeyConst.SECOND_DAY_OF_MONTH:
          return this.getEnumTextByValue(DataType.TotalDayOfMonthEnum, value)
        case DataType.JsonKeyConst.JOB_TYPE:
          return this.getEnumTextByValue(DataType.JobType, value)
        case DataType.JsonKeyConst.PAYMENT_MODE:
          return this.getEnumTextByValue(DataType.PaymentMode, value)
        case DataType.JsonKeyConst.LANGUAGE:
          return this.getEnumTextByValue(DataType.Language, value)
        default:
          return value
      }
    }
  }

  randomElement (arr = []) {
    return arr[Math.floor(Math.random() * arr.length)]
  }

  kebab (str) {
    return (str || '').replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
  }

  verifyInformation (key) {
    let result = null
    switch (key) {
      case 'firstName':
        result = 'First Name'
        break
      case 'lastName':
        result = 'Last Name'
        break
      case 'homePhone':
        result = 'Home Phone'
        break
      case 'cellPhone':
        result = 'Cell Phone'
        break
      case 'email':
        result = 'Email'
        break
      case 'zip':
        result = 'zip'
        break
      case 'city':
        result = 'City'
        break
      case 'address':
        result = 'Address'
        break
      case 'employerName':
        result = 'Employer Name'
        break
      case 'jobTitle':
        result = 'Job Title'
        break
      case 'employerPhone':
        result = 'Employer Phone Number'
        break
      case 'paydayOnHoliday':
        result = 'Payday on Holiday'
        break
      case 'routingNo':
        result = 'Routing Number'
        break
      case 'accountNo':
        result = 'Account Number'
        break
      default:
        break
    }
    return result
  }

  verifyCustomerInformation (valueObject) {
    const _this = this
    const snackbar = {
      display: true,
      message: ''
    }
    const verifucationArray = ['firstName', 'lastName', 'homePhone', 'cellPhone',
      'email', 'city', 'zip', 'address', 'employerName', 'jobTitle', 'employerPhone', 'paydayOnHoliday', 'routingNo', 'accountNo']
    if (this.isNotEmpty(valueObject)) {
      for (const key in valueObject) {
        if (verifucationArray.includes(key)) {
          if (key === 'homePhone' || key === 'cellPhone' || key === 'employerPhone') {
            if (valueObject[key].length !== 14) {
              snackbar.message = 'Invalid ' + _this.verifyInformation(key) + '!'
              snackbar.display = false
              return snackbar
            }
          } else if (key === 'zip') {
            if (_this.isEmpty(valueObject[key]) || valueObject[key].length !== 5) {
              snackbar.message = 'Invalid ' + _this.verifyInformation(key) + '!'
              snackbar.display = false
              return snackbar
            }
          } else if (key === 'jobTitle') {
            if (_this.isEmpty(valueObject[key]) || valueObject[key] === 'Unknown') {
              snackbar.message = 'Invalid ' + _this.verifyInformation(key) + '!'
              snackbar.display = false
              return snackbar
            }
          } else if (key === 'routingNo') {
            if (_this.isEmpty(valueObject[key]) || valueObject[key].length !== 9) {
              snackbar.message = 'Invalid ' + _this.verifyInformation(key) + '!'
              snackbar.display = false
              return snackbar
            }
          } else if (key === 'accountNo') {
            if (_this.isEmpty(valueObject[key]) || (valueObject[key].length < 4 || valueObject[key].length > 17)) {
              snackbar.message = 'Invalid ' + _this.verifyInformation(key) + '!'
              snackbar.display = false
              return snackbar
            }
          } else {
            if (_this.isEmpty(valueObject[key])) {
              snackbar.message = 'Invalid ' + _this.verifyInformation(key) + '!'
              snackbar.display = false
              return snackbar
            }
          }
        }
      }
    }
    return snackbar
  }

  formatConversion (key, value) {
    let result = value
    if (this.isEmpty(value)) {
      return null
    }
    switch (key) {
      case 'firstName':
      case 'middleName':
      case 'lastName':
        result = value.toUpperCase()
        break
      case 'homePhone':
      case 'cellPhone':
      case 'employerPhone':
        result = value.replace(/[^0-9]/gi, '')
        break
      case 'email':
        result = value.toLowerCase()
        break
      default:
        break
    }
    return result
  }
}

export { Tool }
