const DataType = {
  UserDeviceEnum: {
    WEB: { value: 2001, text: 'Web' },
    WAP: { value: 2002, text: 'Web' }
  },
  PAGES: {
    WELCOME: {
      step: 100,
      popup: true,
      back: false,
      chat: false,
      address: '/instant/welcome',
      addressPC: '/instant/welcome',
      next: 'PERSONAL'
    },
    PERSONAL: {
      step: 101,
      popup: true,
      back: true,
      chat: false,
      address: '/instant/profile',
      addressPC: '/instant/profile',
      next: 'MILITARY',
      previous: 'WELCOME'
    },
    EMPLOYMENT: {
      step: 102,
      popup: true,
      back: true,
      chat: false,
      address: '/authentication/employment',
      addressPC: '/instant/employment',
      next: 'BANK',
      previous: 'PERSONAL'
    },
    BASIC_INFO: {
      step: 201,
      back: true,
      chat: false,
      address: '/instant/basic-info',
      addressPC: '/instant/basic-info',
      next: 'LOAN_AMOUNT',
      previous: 'WELCOME'
    },
    BANK: {
      step: 201,
      popup: true,
      back: true,
      chat: false,
      address: '/authentication/bank',
      addressPC: '/instant/bank',
      next: 'DL_IF',
      previous: 'EMPLOYMENT'
    },
    DL_IF: {
      step: 220,
      popup: true,
      back: true,
      chat: false,
      address: '/instant/dl-information',
      addressPC: '/instant/dl-information',
      next: 'IBV',
      previous: 'BANK'
    },
    IBV: {
      step: 202,
      back: false,
      chat: false,
      address: '/instant/decision-logic',
      addressPC: '/instant/decision-logic',
      next: 'DL_VERIFY'
    },
    DL_VERIFY: {
      step: 203,
      back: false,
      chat: true,
      address: '/authentication/verification',
      addressPC: '/instant/verification',
      next: 'LOAN_AMOUNT'
    },
    LOAN_AMOUNT: {
      step: 204,
      back: false,
      chat: true,
      address: '/authentication/option',
      addressPC: '/instant/option',
      next: 'TRANSACTION'
    },
    TRANSACTION: {
      step: 205,
      back: false,
      chat: true,
      address: '/authentication/transaction',
      addressPC: '/instant/transaction',
      next: 'PREVIEW'
    },
    MILITARY: {
      step: 209,
      back: true,
      chat: true,
      address: '/authentication/military',
      addressPC: '/instant/military',
      next: 'EMPLOYMENT',
      previous: 'PERSONAL'
    },
    COMMUNICATION: {
      step: 210,
      back: false,
      chat: true,
      address: '/authentication/communication',
      addressPC: '/instant/communication',
      next: 'PREVIEW'
    },
    PREVIEW: {
      step: 206,
      back: false,
      chat: true,
      address: '/authentication/loan-information',
      addressPC: '/instant/loan-information',
      next: 'SIGNATURE'
    },
    ADVERTISING: {
      step: 207,
      back: false,
      address: '/authentication/advertising',
      addressPC: '/instant/advertising'
    },
    SIGNATURE: {
      step: 208,
      back: false,
      chat: true,
      address: '/authentication/signature',
      addressPC: '/instant/signature',
      next: 'SUCCESS'
    },
    SUCCESS: {
      step: 300,
      back: false,
      chat: true,
      address: '/authentication/congratulate',
      addressPC: '/instant/congratulate'
    },
    NOTIFICATION: {
      step: 400,
      back: false,
      address: '/notification/',
      addressPC: '/instant/notification/'
    }
  },
  SAME_PORTFOLIO: [4, 8, 16],
  PAGE_SIZE: 13,
  WithdrawnReason: {
    ACCOUNT_CLOSED: { value: 20000, text: 'Account closed' },
    BAD_EMPLOYMENT_POSITION: { value: 20001, text: 'Bad Employment/Position' },
    BAD_STATE_ZIP_CODE: { value: 20002, text: 'Bad State/ZIP Code' },
    CUSTOMER_IS_IN_COLLECTIONS: { value: 20003, text: 'Customer is in collections' },
    CUSTOMER_IS_NOT_THE_BK_ACCT_HOLDER: { value: 20004, text: 'Customer is not the bk acct holder' },
    CUSTOMER_IS_UNEMPLOYED: { value: 20005, text: 'Customer is Unemployed' },
    CUSTOMER_HAS_GARNISHMENT: { value: 20006, text: 'Customer has Garnishment' },
    CUSTOMER_HAS_LIQUID_SAVINGS_OR_BUSINESS_ACCOUNT: {
      value: 20007,
      text: 'Customer has liquid, savings or business account'
    },
    CUSTOMER_REQUESTED_NOT_TO_BE_CALLED_AGAIN: { value: 20008, text: 'Customer requested not to be called again' },
    CUSTOMER_IS_DECEASED: { value: 20009, text: 'Customer is deceased' },
    FRAUD: { value: 20010, text: 'FRAUD' },
    INCORRECT_SOCIAL_SECURITY_NUMBER: { value: 20011, text: 'Incorrect Social Security Number' },
    MILITARY: { value: 20012, text: 'Military' },
    DAP_DENIED: { value: 20013, text: 'DAP denied' },
    CUSTOMER_FAILED_TO_ANSWER_THE_AUTHENTICATION_SECURITY_QUESTIONS: {
      value: 20014,
      text: 'Customer failed to answer the authentication/security questions'
    },
    CUSTOMER_HAS_HOLDS_FREEZES_RESTRICTIONS_STOP_PAYMENTS_OR_TCA_IN_THE_LAST_90_DAYS: {
      value: 20015,
      text: 'Customer has holds, freezes, restrictions, stop payments or TCA in the last 90 days'
    },
    UNABLE_TO_DO_VOB_OR_VOE: { value: 20016, text: 'Unable to do VOB or VOE' },
    SPECIAL_CASE: { value: 20017, text: 'Special Case' },
    CUSTOMER_IS_NO_LONGER_INTERESTED: { value: 10000, text: 'Customer is no longer interested' },
    AUTO_WITHDRAWN_DUE_TO_APPLICATION_AGE: { value: 10001, text: 'Application Timeout' },
    BAD_BANK_BAD_ABA: { value: 10002, text: 'Bad Bank/Bad ABA' },
    BAD_CONTACT_INFO: { value: 10003, text: 'Bad Contact Info' },
    DUPLICATE_LEAD: { value: 10004, text: 'Duplicate lead' },
    CUSTOMER_DOES_NOT_MEET_THE_MINIMUM_REQUIRED_AGE: {
      value: 10006,
      text: 'Customer does not meet the minimum required age'
    },
    CUSTOMER_HAS_NO_DDS: { value: 10007, text: 'Customer has no DDs' },
    NOT_QUALIFIED_AS_PER_LOAN_MATRIX: { value: 10008, text: 'Not qualified as per loan matrix' },
    CUSTOMER_HAS_OPEN_LOAN_WITH_ANOTHER_CO: { value: 10009, text: 'Customer has open loan with another CO' },
    PREPAID_CARD_DEBIT_CARD: { value: 10010, text: 'Prepaid card / Debit card' },
    LANGUAGE_BARRIER: { value: 10011, text: 'Language Barrier : { value: not Spanish nor English).' },
    UNABLE_TO_COMPLETE_CORRECTION_CUSTOMER_IS_UNREACHABLE_FOR_MORE_THAN_2_DAYS: {
      value: 10012,
      text: 'Unable to complete correction, customer is unreachable for more than 2 days'
    },
    UNPREDICTABLE_INCORRECT_PAYROLL_FREQUENCY: {
      value: 10013,
      text: 'Unpredictable/Incorrect Payroll Frequency : { value: Monthly, Daily, Semi-monthly, Weekend)'
    },
    MINIMUM_INCOME_NOT_NET: { value: 10014, text: 'MinimumIncomeNotMet' },
    UNABLE_VER_BANK_INFORMATION: { value: 10015, text: 'UnableVerBankInfo' },
    PAPER_CHECK: { value: 10016, text: 'PaperCheck' },
    FACTOR_TRUST_FAILED: { value: 10017, text: 'FactorTrustFailed' },
    VERIFY_FAILED: { value: 10018, text: 'Verify Failed' },
    AUTO_WITHDRAWN: { value: 10019, text: 'Auto Withdrawn' },
    OTHER: { value: 10020, text: 'Other' },
    AMOUNT_IS_TOO_LOW: { value: 10021, text: 'AmountIsTooLow' }
  },

  CorrectionReason: [
    {
      Personal: [
        'Name Verification',
        'DOB Verification',
        'SSN Verification',
        'Missing / Incorrect Military Notes',
        'Missing / Incorrect C Notes',
        'Missing / Incorrect H Notes',
        'Missing / Incorrect VIP/CCI Notes',
        'Update Home Number',
        'Update  Email Address',
        'Update DNC Status',
        'Update Mobile Number',
        'Update  Military Tagging',
        'Update Address',
        'Update Name',
        'Verify  Military Tagging'
      ]
    },
    {
      Employment: [
        'Missing / Incorrect VOE Notes',
        'Missing / Incorrect W# validation  Notes',
        'Update Recent Pay Date',
        'Update Payroll Frequency',
        'Update Pay Flex',
        'Update Work Phone Number',
        'Update Payroll Type',
        'Update Employer Name',
        'Update Position',
        'Verify Recent Pay Date',
        'Verify Affiliation Payroll and Employer Name',
        'Verify Employment - Regular',
        'Verify Employment - Strong',
        'Verify Payroll Frequency',
        'Verify Pay Flex',
        'Verify Work Phone Number',
        'Verify Payroll Type',
        'Verify Employer Name',
        'Verify the Position'
      ]
    },
    {
      Bank: [
        'Missing / Incorrect Cleared Payment Notes',
        'Missing / Incorrect SE Notes',
        'Missing / Incorrect Auto bank Notes',
        'Update Name of Payroll',
        'Update DDs availability on the account',
        'Update 90 Day Questions',
        'Update Verification Method',
        'Update Address Verified with Banker',
        'Update Unlimited ACH Credit/Debit',
        'Update Liquid Account',
        'Update Account Holder Option',
        'Update DDs',
        'Update Payroll Dates',
        'Update Bank Available Balance',
        'Update Bank Phone Number',
        'Update Bank Account Type',
        'Update Bank Routing Number',
        'Update Account Number',
        'Update Bank Name',
        'Verify DDs availability on the account',
        'Verify 90 Day Questions',
        'Verify Open Loans',
        'Verify Bank Available Balance',
        'Verify Ending balances',
        'Verify Address Verified with Banker',
        'Verify Unlimited ACH Credit/Debit',
        'Verify Liquid Account',
        'Verify Bank Account Type',
        'Verify Account Holder Option',
        'Verify Bank Routing Number',
        'Verify Account Number',
        'Verify DDs',
        'Verify Payroll Dates',
        'Verify Bank Phone Number',
        'Verify Bank Name',
        'Verify the mandatory question for Special VOB',
        'Verify the mandatory question for Closed Account',
        'Verify the affiliation of the bank',
        'Missing DL on File',
        'Need to perform VOB Fast pass is not applicable',
        'DL required to establish correct PF',
        'DL required due to Bad performance history',
        'DL required due to previously denied due to open loans',
        'DL required due to discrepance of Bank details',
        'Verify the Name of the Merchant (Wire)',
        'Verify the Name of the Merchant (Pending transcation)',
        'Verify the Name of the Merchant (Reversals,Stop Payment,TCA)'
      ]
    },
    {
      Payment: [
        'Lower Loan Amount',
        'Missing / Incorrect Returning Cust Notes',
        'Missing / Incorrect LT Notes',
        'Missing / Incorrect EPO Notes',
        'Incorrect / Update Payment Schedule',
        'Incorrect / Update # of installment',
        'Incorrect / Update Effectivity',
        'Incorrect / Update Expedite Payment',
        'Incorrect / Update Payment Mode',
        'Incorrect / Update Loan Amount',
        'Incorrect / Update Program'
      ]
    },
    {
      Document: [
        'Update LA - Name/Signature',
        'Incorrect / Update LA - Bank Details',
        'DAP Additional Information',
        'Verify why IP is not within US'
      ]
    }
  ],

  WorkQueueType: {
    AGENT_REVIEW: { value: 101, text: 'Agent Review' },
    CORRECTOR_REVIEW: { value: 102, text: 'Corrector Review' },
    UNDERWRITER_REVIEW: { value: 103, text: 'Underwriter Review' },
    TRIBE_REVIEW: { value: 104, text: 'Tribe Review' },
    POSITIVE_WITHDRAWN: { value: 105, text: 'Positive Withdraw' },
    NEGATIVE_WITHDRAWN: { value: 106, text: 'Negative Withdraw' },
    TO_BE_SIGNED: { value: 201, text: 'To Be Signed' },
    TO_BE_SUBMITTED: { value: 202, text: 'To Be Submitted' }
  },

  Category: {
    NEW_CUSTOMER: { value: 1, text: 'New Customer' },
    RETURNING_CUSTOMER: { value: 2, text: 'Returning Customer' },
    COLLECTION_CUSTOMER: { value: 3, text: 'Collection Customer' },
    ONLINE_CUSTOMER: { value: 4, text: 'Online Program' },
    JARVIS_AUTO: { value: 6, text: 'Auto' },
    DISCARD_CUSTOMER: { value: -1, text: 'Discard Customer' },
    OTHERS: { value: 0, text: 'Others' }
  },
  Gender: [
    { value: 1, text: 'Male' },
    { value: 2, text: 'Female' },
    { value: 9, text: 'Unknown' }
  ],
  NotificationTypeConst: [
    { value: 1, text: 'SES_MESSAGE' },
    { value: 2, text: 'SMS_MESSAGE' }
  ],
  State: [
    { value: 'AL', text: 'AL - Alabama' },
    { value: 'AK', text: 'AK - Alaska' },
    { value: 'AZ', text: 'AZ - Arizona' },
    { value: 'AR', text: 'AR - Arkansas' },
    { value: 'CA', text: 'CA - California' },
    { value: 'CO', text: 'CO - Colorado' },
    { value: 'CT', text: 'CT - Connecticut' },
    { value: 'DE', text: 'DE - Delaware' },
    { value: 'DC', text: 'DC - District of Columbia' },
    { value: 'FL', text: 'FL - Florida' },
    { value: 'GA', text: 'GA - Georgia' },
    { value: 'HI', text: 'HI - Hawaii' },
    { value: 'ID', text: 'ID - Idaho' },
    { value: 'IL', text: 'IL - Illinois' },
    { value: 'IN', text: 'IN - Indiana' },
    { value: 'IA', text: 'IA - Iowa' },
    { value: 'KS', text: 'KS - Kansas' },
    { value: 'KY', text: 'KY - Kentucky' },
    { value: 'LA', text: 'LA - Louisiana' },
    { value: 'ME', text: 'ME - Maine' },
    { value: 'MD', text: 'MD - Maryland' },
    { value: 'MA', text: 'MA - Massachusetts' },
    { value: 'MI', text: 'MI - Michigan' },
    { value: 'MN', text: 'MN - Minnesota' },
    { value: 'MS', text: 'MS - Mississippi' },
    { value: 'MO', text: 'MO - Missouri' },
    { value: 'MT', text: 'MT - Montana' },
    { value: 'NE', text: 'NE - Nebraska' },
    { value: 'NV', text: 'NV - Nevada' },
    { value: 'NH', text: 'NH - New Hampshire' },
    { value: 'NJ', text: 'NJ - New Jersey' },
    { value: 'NM', text: 'NM - New Mexico' },
    { value: 'NY', text: 'NY - New York' },
    { value: 'NC', text: 'NC - North Carolina' },
    { value: 'ND', text: 'ND - North Dakota' },
    { value: 'OH', text: 'OH - Ohio' },
    { value: 'OK', text: 'OK - Oklahoma' },
    { value: 'OR', text: 'OR - Oregon' },
    { value: 'PA', text: 'PA - Pennsylvania' },
    { value: 'PR', text: 'PR - Puerto Rico' },
    { value: 'RI', text: 'RI - Rhode Island' },
    { value: 'SC', text: 'SC - South Carolina' },
    { value: 'SD', text: 'SD - South Dakota' },
    { value: 'TN', text: 'TN - Tennessee' },
    { value: 'TX', text: 'TX - Texas' },
    { value: 'UT', text: 'UT - Utah' },
    { value: 'VT', text: 'VT - Vermont' },
    { value: 'VA', text: 'VA - Virginia' },
    { value: 'WA', text: 'WA - Washington' },
    { value: 'WV', text: 'WV - West Virginia' },
    { value: 'WI', text: 'WI - Wisconsin' },
    { value: 'WY', text: 'WY - Wyoming' }
  ],
  Language: [
    { value: 101, text: 'English' },
    { value: 102, text: 'Spanish' }
  ],
  PayrollType: [
    { value: 18000, text: 'Direct Deposit' },
    { value: 18001, text: 'Paper Check' },
    { value: 18002, text: 'Cash' },
    { value: 18003, text: 'Unemployed Insurance' }
  ],
  JobType: [
    { value: 1000, text: 'Full Time' },
    { value: 1001, text: 'Part Time' }
  ],
  PayrollFrequency: [
    { value: 17003, text: 'Weekly', period: 7, count: 52, code: 'W' },
    { value: 17000, text: 'Bi-Weekly', period: 14, count: 26, code: 'B' },
    { value: 17002, text: 'Semi-Monthly', period: 15, count: 24, code: 'S' },
    { value: 17001, text: 'Monthly', period: 30, count: 12, code: 'M' }
  ],
  PayrollFrequencyEnum: {
    WEEKLY: { value: 17003, text: 'Weekly', period: 7, count: 52, code: 'W' },
    BI_WEEKLY: { value: 17000, text: 'Bi-Weekly', period: 14, count: 26, code: 'B' },
    SEMI_MONTHLY: { value: 17002, text: 'Semi-Monthly', period: 15, count: 24, code: 'S' },
    MONTHLY: { value: 17001, text: 'Monthly', period: 30, count: 12, code: 'M' }
  },
  IncomeType: {
    D: { value: 7000, text: 'Disability Income' },
    S: { value: 7001, text: 'Social Security' },
    E: { value: 7002, text: 'Employed' },
    U: { value: 7003, text: 'Unemployed' },
    P: { value: 7004, text: 'Pension' },
    O: { value: 7005, text: 'Others (Child Support, Welfare etc.) ' }
  },
  Source: {
    PURCHASE: { value: 1, text: 'Purchase' },
    RETURNING: { value: 2, text: 'Returning' },
    EXPRESS: { value: 4, text: 'Express' },
    TRANSFER: { value: 8, text: 'Loan Transfer' },
    MARKET: { value: 16, text: 'Market' }
  },
  PageJump: {
    8: '/notification/102',
    524288: '/notification/100',
    32: '/notification/202',
    64: '/notification/200',
    128: '/notification/201',
    1073741824: '/notification/-200',
    '-2147483648': '/notification/-200'
  },
  LoanStatus: {
    PENDING_ORIGINATION: { value: 10001, text: 'Pending Origination' },
    CUSTOMER_REVIEW: { value: 20001, text: 'Customer Review' },
    AGENT_REVIEW: { value: 20002, text: 'Agent Review' },
    CHAT_REVIEW: { value: 20003, text: 'Chat Review' },
    UNDERWRITER_REVIEW: { value: 20004, text: 'Underwriter Review' },
    TRIBE_REVIEW: { value: 20005, text: 'Tribe Review' },
    CORRECTOR_REVIEW: { value: 20006, text: 'Corrector Review' },

    PENDING_APPROVED: { value: 30001, text: 'Pending Approved', prefix: 'post-origination' },
    APPROVED: { value: 30002, text: 'Approved', prefix: 'post-origination' },

    NEW: { value: 40001, text: 'New', prefix: 'post-origination' },
    GOOD_STANDING: { value: 40002, text: 'Good Standing', prefix: 'post-origination' },
    PROCESSING: { value: 40003, text: 'Processing', prefix: 'post-origination' },
    WARNING: { value: 40004, text: 'Warning', prefix: 'post-origination' },
    PENDING_TRANSFERRED_PAID_OFF: { value: 40005, text: 'Pending Transferred Paid Off', prefix: 'post-origination' },

    PENDING_COLLECTION: { value: 50001, text: 'Pending Collection', prefix: 'collection' },
    IN_HOUSE_COLLECTION: { value: 50101, text: 'In House Collection', prefix: 'collection' },
    PENDING_IN_HOUSE_APPROVE: { value: 50102, text: 'Pending In House Approve', prefix: 'collection' },
    IN_HOUSE_REPLAN: { value: 50103, text: 'In House Replan', prefix: 'collection' },
    IN_HOUSE_SETTLEMENT: { value: 50104, text: 'In House Settlement', prefix: 'collection' },
    PENDING_OUT_HOUSE_COLLECTION: { value: 50201, text: 'Pending Out House Collection', prefix: 'collection' },
    OUT_HOUSE_COLLECTION: { value: 50202, text: 'Out House Collection', prefix: 'collection' },
    PENDING_BANKRUPTCY: { value: 50301, text: 'Pending Bankruptcy', prefix: 'collection' },
    PENDING_CCCS: { value: 50401, text: 'Pending CCCS', prefix: 'collection' },
    PENDING_CANCELLATION: { value: 50501, text: 'Pending Cancellation', prefix: 'collection' },

    POSITIVE_WITHDRAWN: { value: 60001, text: 'Positive Withdrawn', prefix: 'pre-origination' },
    NEGATIVE_WITHDRAWN: { value: 60002, text: 'Negative Withdrawn', prefix: 'pre-origination' },
    MANUALLY_VOIDED: { value: 60101, text: 'Manually Voided', prefix: 'post-origination' },
    VOIDED: { value: 60102, text: 'Voided', prefix: 'post-origination' },
    GOOD_STANDING_PAID_OFF: { value: 60201, text: 'Good Standing Paid Off', prefix: 'post-origination' },
    PAID_OFF: { value: 60202, text: 'Paid Off', prefix: 'post-origination' },
    WARNING_PAID_OFF: { value: 60203, text: 'Warning Paid Off', prefix: 'post-origination' },
    TRANSFERRED_PAID_OFF: { value: 60204, text: 'Transferred Paid Off', prefix: 'post-origination' },
    IN_HOUSE_REPLAN_PAID_OFF: { value: 60205, text: 'In House Replan Paid Off', prefix: 'post-origination' },
    IN_HOUSE_SETTLEMENT_PAID_OFF: { value: 60206, text: 'In House Settlement Paid Off', prefix: 'post-origination' },
    OUT_HOUSE_PAID_OFF: { value: 60207, text: 'Out House Paid Off', prefix: 'post-origination' },
    CLOSED: { value: 60301, text: 'Closed', prefix: 'collection' },
    IN_HOUSE_REJECTED: { value: 60401, text: 'In House Rejected', prefix: 'collection' },
    OUT_HOUSE_REJECTED: { value: 60402, text: 'Out House Rejected', prefix: 'collection' },
    BANKRUPTCY: { value: 60501, text: 'Bankruptcy', prefix: 'collection' },
    CCCS: { value: 60601, text: 'CCCS', prefix: 'collection' },
    CANCELLATION: { value: 60701, text: 'Cancellation' }
  },
  FollowUpType: {
    RELATIVE: { value: 201, text: 'Relative' },
    ABSOLUTE: { value: 202, text: 'Absolute' }
  },
  PaydayOnHolidayType: [
    { value: -1, text: 'Previous Business Day' },
    { value: 1, text: 'Next Business Day' }
  ],
  BankAccountType: [
    { value: 3000, text: 'Checking Account', code: 'S' },
    { value: 3001, text: 'Savings Account', code: 'C' },
    { value: 3002, text: 'Money Market', code: 'M' }
  ],
  VoB: [
    { value: 1, text: 'Bank Call' },
    { value: 2, text: 'Decision Logic' },
    { value: 3, text: 'Documents' }
  ],
  VoE: [
    { value: 1, text: 'Strong' },
    { value: 2, text: 'Regular' },
    { value: 3, text: 'None' }
  ],
  TransactionMode: [
    { value: 15000, text: 'ACH' },
    { value: 15001, text: 'Cash' },
    { value: 15002, text: 'Cashier Check' },
    { value: 15003, text: 'Credit Card' },
    { value: 15004, text: 'Debit Card' },
    { value: 15005, text: 'Money Gram' },
    { value: 15006, text: 'Money Order' },
    { value: 15007, text: 'Personal Check' },
    { value: 15008, text: 'Western Union' },
    { value: 15009, text: 'Wire' },
    { value: 15010, text: 'Loan Transfer' },
    { value: 15011, text: 'None' },
    { value: 15012, text: 'Wage Assigment' }
  ],
  TransactionType: [
    { value: '14000', text: 'Credit' },
    { value: '14001', text: 'Debit' }
  ],
  ChangeType: [
    { value: 0, text: 'Normal' },
    { value: 101, text: 'Pay Off' },
    { value: 102, text: 'Pay Off In N Payments' },
    { value: 103, text: 'Pay Down' },
    { value: 104, text: 'Revoke Future ACH' },
    { value: 105, text: 'Change Payroll Date' },
    { value: 106, text: 'Change Payroll Frequency' },
    { value: 107, text: 'Change Payment Mode' },
    { value: 201, text: 'Skip Payment' },
    { value: 202, text: 'Makeup Payment' },
    { value: 203, text: 'Change Payment Amount' },
    { value: 204, text: 'Change Payment Mode' },
    { value: 205, text: 'Change Payment Date' },
    { value: 301, text: 'Reverse Credit' },
    { value: 302, text: 'Refund Debit' },
    { value: 303, text: 'Refund Bank Fee' },
    { value: 304, text: 'Refund Full Payment' },
    { value: 305, text: 'Refund Partial Payment' },
    { value: 306, text: 'Refund Payment' },
    { value: 901, text: 'Manual Reject' },
    { value: 902, text: 'Cancel Changes' },
    { value: 903, text: 'Manual Checked' }
  ],
  PaymentMode: [{ value: 15000, text: 'ACH' }],
  FirstDayOfWeek: [
    { value: 2, text: 'Monday' },
    { value: 3, text: 'Tuesday' },
    { value: 4, text: 'Wednesday' },
    { value: 5, text: 'Thursday' },
    { value: 6, text: 'Friday' }
  ],
  DayOfMonth: [
    { value: -11, text: 'Last B-Day Of Month' },
    { value: -12, text: 'Mid Of Month' },
    { value: -13, text: 'First B-Day Of Month' },
    { value: 1, text: '1' },
    { value: 2, text: '2' },
    { value: 3, text: '3' },
    { value: 4, text: '4' },
    { value: 5, text: '5' },
    { value: 6, text: '6' },
    { value: 7, text: '7' },
    { value: 8, text: '8' },
    { value: 9, text: '9' },
    { value: 10, text: '10' },
    { value: 11, text: '11' },
    { value: 12, text: '12' },
    { value: 13, text: '13' },
    { value: 14, text: '14' },
    { value: 15, text: '15' },
    { value: 16, text: '16' },
    { value: 17, text: '17' },
    { value: 18, text: '18' },
    { value: 19, text: '19' },
    { value: 20, text: '20' },
    { value: 21, text: '21' },
    { value: 22, text: '22' },
    { value: 23, text: '23' },
    { value: 24, text: '24' },
    { value: 25, text: '25' },
    { value: 26, text: '26' },
    { value: 27, text: '27' },
    { value: 28, text: '28' },
    { value: 29, text: '29' },
    { value: 30, text: '30' },
    { value: 31, text: '31' }
  ],
  BooleanInBinary: [
    { value: true, text: 'Yes' },
    { value: false, text: 'No' }
  ],
  IbvStatus: [
    { value: 0, text: 'Not Started' },
    { value: 1, text: 'Started' },
    { value: 3, text: 'Verified' },
    { value: -1, text: 'Account Error' },
    { value: -2, text: 'Bank Error' }
  ],
  SignStatus: [
    { value: 0, text: 'Not Signed' },
    { value: 1, text: 'Signed' },
    { value: 2, text: 'Expired' },
    { value: 3, text: 'Cancelled' },
    { value: 4, text: 'Voided' },
    { value: 5, text: 'Suspended' }
  ],
  ReviewStatus: [
    { value: 101, text: 'Not Verified' },
    { value: 102, text: 'Verified' },
    { value: 103, text: 'Rejected' }
  ],
  ReviewStatusEnum: {
    NOT_VERIFY: { value: 101, text: 'Not Verified' },
    VERIFY: { value: 102, text: 'Verified' },
    CORRECT: { value: 103, text: 'Rejected' }
  },
  CustomerOptInType: {
    EMAIL: { value: 1, text: 'Email' },
    CELL_PHONE: { value: 2, text: 'Cell Phone' },
    HOME_PHONE: { value: 3, text: 'Home Phone' }
  },
  PortfolioEnum: {
    INBOX_LOAN: { value: 1, text: 'Inbox Loan' },
    FIRST_LOAN: { value: 2, text: 'Expired' },
    BETTER_DAY_LOAN: { value: 4, text: 'Canceled' },
    WEST_SIDE_LENDING: { value: 7, text: 'Voided' },
    EAST_LINE_LENDING: { value: 8, text: 'Voided' },
    TRAINING_ACCOUNT: { value: 9, text: 'Voided' },
    RIGHT_NOW_LOAN: { value: 10, text: 'Voided' }
  },
  CorrectionReasonEnum: [
    {
      text: 'Personal',
      value: '100',
      items: [
        { text: 'Name verify', value: 101 },
        { value: 102, text: 'DOB Verification' },
        {
          value: 103,
          text: 'SSN Verification'
        },
        { value: 104, text: 'Missing / Incorrect Military Notes' },
        {
          value: 105,
          text: 'Missing / Incorrect C Notes'
        },
        { value: 106, text: 'Missing / Incorrect H Notes' },
        {
          value: 107,
          text: 'Missing / Incorrect VIP/CCI Notes'
        },
        { value: 108, text: 'Update Home Number' },
        { value: 109, text: 'Update  Email Address' },
        {
          value: 110,
          text: 'Update DNC Status'
        },
        { value: 111, text: 'Update Mobile Number' },
        { value: 112, text: 'Update  Military Tagging' },
        {
          value: 113,
          text: 'Update Address'
        },
        { value: 114, text: 'Update Name' },
        { value: 115, text: 'Verify  Military Tagging' }
      ]
    },
    {
      text: 'Bank',
      value: '200',
      items: [
        { text: 'Missing / Incorrect Cleared Payment Notes', value: 201 },
        {
          value: 202,
          text: 'Missing / Incorrect SE Notes'
        },
        { value: 203, text: 'Missing / Incorrect Auto bank Notes' },
        {
          value: 204,
          text: 'Update Name of Payroll'
        },
        { value: 205, text: 'Update DDs availability on the account' },
        {
          value: 206,
          text: 'Update 90 Day Questions'
        },
        { value: 207, text: 'Update Verification Method' },
        {
          value: 208,
          text: 'Update Address Verified with Banker'
        },
        { value: 209, text: 'Update Unlimited ACH Credit/Debit' },
        {
          value: 210,
          text: 'Update Liquid Account'
        },
        { value: 211, text: 'Update Account Holder Option' },
        { value: 212, text: 'Update DDs' },
        {
          value: 213,
          text: 'Update Payroll Dates'
        },
        { value: 214, text: 'Update Bank Available Balance' },
        {
          value: 214,
          text: 'Update Bank Phone Number'
        },
        { value: 216, text: 'Update Bank Account Type' },
        {
          value: 217,
          text: 'Update Bank Routing Number'
        },
        { value: 218, text: 'Update Account Number' },
        { value: 219, text: 'Update Bank Name' },
        {
          value: 220,
          text: 'Verify DDs availability on the account'
        },
        { value: 221, text: 'Verify 90 Day Questions' },
        { value: 222, text: 'Verify Open Loans' },
        {
          value: 223,
          text: 'Verify Bank Available Balance'
        },
        { value: 224, text: 'Verify Ending balances' },
        {
          value: 225,
          text: 'Verify Address Verified with Banker'
        },
        { value: 226, text: 'Verify Unlimited ACH Credit/Debit' },
        {
          value: 227,
          text: 'Verify Liquid Account'
        },
        { value: 228, text: 'Verify Bank Account Type' },
        {
          value: 229,
          text: 'Verify Account Holder Option'
        },
        { value: 230, text: 'Verify Bank Routing Number' },
        { value: 231, text: 'Verify Account Number' },
        {
          value: 232,
          text: 'Verify DDs'
        },
        { value: 233, text: 'Verify Payroll Dates' },
        { value: 234, text: 'Verify Bank Phone Number' },
        {
          value: 235,
          text: 'Verify Bank Name'
        },
        { value: 236, text: 'Verify the mandatory question for Special VOB' },
        {
          value: 237,
          text: 'Verify the mandatory question for Closed Account'
        },
        { value: 238, text: 'Verify the affiliation of the bank' },
        {
          value: 239,
          text: 'Missing DL on File'
        },
        { value: 240, text: 'Need to perform VOB Fast pass is not applicable' },
        {
          value: 241,
          text: 'DL required to establish correct PF'
        },
        { value: 242, text: 'DL required due to Bad performance history' },
        {
          value: 243,
          text: 'DL required due to previously denied due to open loans'
        },
        { value: 244, text: 'DL required due to discrepance of Bank details' },
        {
          value: 245,
          text: 'Verify the Name of the Merchant (Wire)'
        },
        { value: 246, text: 'Verify the Name of the Merchant (Pending transcation)' },
        {
          value: 247,
          text: 'Verify the Name of the Merchant (Reversals,Stop Payment,TCA)'
        }
      ]
    },
    {
      text: 'Employment',
      value: '300',
      items: [
        { text: 'Missing / Incorrect VOE Notes', value: 301 },
        {
          value: 302,
          text: 'Missing / Incorrect W# validation  Notes'
        },
        { value: 303, text: 'Update Recent Pay Date' },
        { value: 304, text: 'Update Payroll Frequency' },
        {
          value: 305,
          text: 'Update Pay Flex'
        },
        { value: 306, text: 'Update Pay Flex' },
        { value: 307, text: 'Update Work Phone Number' },
        {
          value: 308,
          text: 'Update Payroll Type'
        },
        { value: 309, text: 'Update Employer Name' },
        { value: 310, text: 'Update Position' },
        {
          value: 311,
          text: 'Verify Affiliation Payroll and Employer Name'
        },
        { value: 312, text: 'Verify Recent Pay Date' },
        { value: 313, text: 'Verify Employment - Regular' },
        {
          value: 314,
          text: 'Verify Employment - Strong'
        },
        { value: 315, text: 'Verify Payroll Frequency' },
        { value: 316, text: 'Verify Pay Flex' },
        {
          value: 317,
          text: 'Verify Work Phone Number'
        },
        { value: 318, text: 'Verify Payroll Type' },
        { value: 319, text: 'Verify Employer Name' },
        {
          value: 320,
          text: 'Verify the Position'
        }
      ]
    },
    {
      text: 'Payment',
      value: '400',
      items: [
        { text: 'Lower Loan Amount', value: 401 },
        {
          value: 402,
          text: 'Missing / Incorrect Returning Cust Notes'
        },
        { value: 403, text: 'Missing / Incorrect LT Notes' },
        { value: 404, text: 'Missing / Incorrect EPO Notes' },
        {
          value: 405,
          text: 'Incorrect / Update Payment Schedule'
        },
        { value: 406, text: 'Incorrect / Update # of installment' },
        {
          value: 407,
          text: 'Incorrect / Update Effectivity'
        },
        {
          value: 408,
          text: 'Incorrect / Update Expedite Payment'
        },
        { value: 409, text: 'Incorrect / Update Payment Mode' },
        {
          value: 410,
          text: 'Incorrect / Update Loan Amount'
        },
        {
          value: 411,
          text: 'Incorrect / Update Program'
        }
      ]
    },
    {
      text: 'Documents',
      value: '500',
      items: [
        { text: 'Update LA - Name/Signature', value: 501 },
        {
          value: 502,
          text: 'Incorrect / Update LA - Bank Details'
        },
        { value: 503, text: 'DAP Additional Information' },
        {
          value: 504,
          text: 'Verify why IP is not within US'
        }
      ]
    }
  ],
  OperatorIdConst: {
    SYSTEM_ACTION: { value: -1, text: 'SYSTEM_ACTION' },
    CUSTOMER_ACTION: { value: -2, text: 'CUSTOMER_ACTION' }
  },
  EventSourceEnum: {
    ENTER_LEAVE_DL_AUTHORIZATION_IFRAME: { value: 120102004, text: 'Decision Logic Authorization Iframe' }
  },
  EventTypeEnum: {
    ENTER_PAGE: { value: 1001, text: 'Enter Page' },
    LEAVE_PAGE: { value: 1002, text: 'Leave Page' },
    // Application Log
    APPLICATION_STATUS_EVENT: { value: 11000, text: 'Application Status Event', ignore: true },
    APPLICATION_STATUS_CHANGE: { value: 11001, text: 'Application Status Change' },
    APPLICATION_REGISTRY_CHANGE: { value: 10002, text: 'Application Registry Change' },
    EXPRESS_EVENT: { value: 12000, text: 'Express Event' },
    EXPRESS: { value: 12001, text: 'Express' },
    // Document Log
    DOCUMENT_EVENT: { value: 21000, text: 'Document Event' },
    LOAN_AGREEMENT: { value: 21001, text: 'Loan Agreement' },
    CERTIFICATE_OF_EVIDENCE: { value: 21002, text: 'Certificate of Evidence' },
    IDENTIFY_DOCUMENT: { value: 21003, text: 'Identity Document' },
    BANK_DOCUMENT: { value: 21004, text: 'Bank Account' },
    OTHERS_DOCUMENT: { value: 21005, text: 'Other Document' },
    BANKRUPTCY: { value: 21006, text: 'Bankruptcy' },
    CCCS: { value: 21007, text: 'Cccs' },
    PAPER_CHECK: { value: 21008, text: 'Paper Check' },
    // Note Log
    NOTE_EVENT: { value: 31000, text: 'Note Event' },
    NOTE_CREATE: { value: 31001, text: 'Note Create' },
    NOTE_CHAT: { value: 31002, text: 'Note Chat' },
    // Operation Log
    OPERATION_EVENT: { value: 41000, text: 'Operation Event' },
    SYSTEM_IMPORT: { value: 41001, text: 'System Import' },
    LOCK: { value: 41002, text: 'Lock' },
    UNLOCK: { value: 41003, text: 'Unlock' },
    GRAB: { value: 41004, text: 'Grab' },
    FOLLOW_UP: { value: 41005, text: 'Follow Up' },
    SEND_LOAN_AGREEMENT: { value: 41006, text: 'Send Loan Agreement' },
    SEND_EMAIL: { value: 41007, text: 'Send Email' },
    CANCEL_FOLLOW_UP: { value: 41008, text: 'Cancel Follow Up' },
    TRIGGER_FOLLOW_UP: { value: 41009, text: 'Trigger Follow Up' },
    COLLECTION_SEND_EMAIL: { value: 41010, text: 'Collection Send Email' },
    // Payment Log
    PPS_TO_LCS_EVENT: { value: 51000, text: 'Move to LCS Event' },
    PPS_TO_LCS: { value: 51002, text: 'Application Status PPS to LCS' },
    TRANSACTION_STATUS_EVENT: { value: 52000, text: 'Transaction Status Event' },
    TRANSACTION_STATUS_CHANGE: { value: 52001, text: 'Transaction Status Change' },
    TRANSACTION_AMOUNT_CHANGE: { value: 52003, text: 'Transaction Amount Change' },
    DELETE_TRANSACTION_SCHEDULE: { value: 52004, text: 'Delete Transaction Schedule' },
    UPDATE_TRANSACTION_SCHEDULE: { value: 52005, text: 'Update Transaction Schedule' },
    REQUEST_CHANGE_EVENT: { value: 53000, text: 'Request Change Event' },
    REQUEST_CHANGE: { value: 53001, text: 'Request Change' },
    REGISTRY_CHANGE_EVENT: { value: 54000, text: 'Registry Change Event' },
    REGISTRY_CHANGE: { value: 54001, text: 'Registry Change' },
    // Maintenance Log
    MAINTENANCE_EVENT: { value: 60000, text: 'MAINTENANCE_EVENT' },
    ANT_EVENT: { value: 61000, text: 'ANT_EVENT' },
    ANT_PARAMETER_MODIFICATION: { value: 61011, text: 'ANT_PARAMETER_MODIFICATION' },
    ANT_SEND: { value: 61012, text: 'ANT_SEND' },
    ANT_RETURN: { value: 61013, text: 'ANT_RETURN' },
    ANT_OVERRIDE: { value: 61014, text: 'ANT_OVERRIDE' },
    ANT_MASSAGE: { value: 61020, text: 'ANT_MASSAGE' },
    // Transaction Log
    TRANSACTION_EVENT: { value: 70000, text: 'TRANSACTION_EVENT' },
    TRANSACTION_SEND: { value: 71011, text: 'TRANSACTION_SEND' },
    TRANSACTION_RETURN: { value: 71012, text: 'TRANSACTION_RETURN' },
    TRANSACTION_AUTO_CHECKED: { value: 71013, text: 'TRANSACTION_AUTO_CHECKED' }
  },
  DocumentTypeEnum: {
    IDENTIFY_DOCUMENT: { value: 1, text: 'Identify document' },
    BANK_DOCUMENT: { value: 2, text: 'Bank document' },
    OTHERS_DOCUMENT: { value: 3, text: 'Others document' },
    LOAN_AGREEMENT: { value: 4, text: 'Loan Agreement' },
    CERTIFICATE_OF_EVIDENCE: { value: 5, text: 'Certificate of Evidence' },
    BANKRUPTCY: { value: 6, text: 'Bankruptcy' },
    CCCS: { value: 7, text: 'Cccs' },
    PAPER_CHECK: { value: 8, text: 'Paper Check' },
    OTHER: { value: 9, text: 'Other' }
  },
  LockApplicationReasonEnum: {
    GRAB_APPLICATION: { value: 0, text: 'Grab Application' },
    GENERATE_APPLICATION: { value: 1, text: 'Generate Application' },
    CUSTOMER_SEARCH: { value: 2, text: 'Customer Search' },
    MANUAL_LOCK: { value: 3, text: 'Manual Lock' },
    AUTOMATIC_LOCK: { value: 4, text: 'Automatic Lock' }
  },
  ChangeTypeEnum: {
    NORMAL: { value: 0, text: 'Normal' },
    PAY_OFF: { value: 101, text: 'Pay Off' },
    PAY_OFF_IN_N: { value: 102, text: 'Pay Off In N Payments' },
    PAY_DOWN: { value: 103, text: 'Pay Down' },
    REVOKE_ACH: { value: 104, text: 'Revoke Future ACH' },
    CHANGE_DATE: { value: 105, text: 'Change Payroll Date' },
    CHANGE_FREQUENCY: { value: 106, text: 'Change Payroll Frequency' },
    CHANGE_MODE: { value: 107, text: 'Change Payment Mode' },
    CHANGE_PAYDAY_ON_HOLIDAY: { value: 108, text: 'Change Payday On Holiday' },
    CHANGE_PAYDAY_ON_AVAILABLE: { value: 109, text: 'Change Payday On Available' },
    ASSIGNED_SKIP: { value: 201, text: 'Skip Payment' },
    ASSIGNED_MAKEUP: { value: 202, text: 'Makeup Payment' },
    ASSIGNED_AMOUNT: { value: 203, text: 'Change Payment Amount' },
    ASSIGNED_MODE: { value: 204, text: 'Change Payment Mode' },
    ASSIGNED_DATE: { value: 205, text: 'Change Payment Date' },
    REFUND_CREDIT: { value: 301, text: 'Reverse Credit' },
    REVERSE_DEBIT: { value: 302, text: 'Reverse Debit' },
    REFUND_BANK_FEE: { value: 303, text: 'Refund Bank Fee' },
    REFUND_FULL_PAYMENT: { value: 304, text: 'Refund Full Payment' },
    REFUND_PARTIAL_PAYMENT: { value: 305, text: 'Refund Partial Payment' },
    REFUND_PAYMENT: { value: 306, text: 'Refund Payment' },
    MANUAL_REJECT: { value: 901, text: 'Manual Reject' },
    CANCEL_CHANGE: { value: 902, text: 'Cancel Changes' },
    MANUAL_CHECKED: { value: 903, text: 'Manual Checked' }
  },
  TransactionModeEnum: {
    ACH: { value: 15000, text: 'ACH' },
    CASH: { value: 15001, text: 'Cash' },
    CASHIER_CHECK: { value: 15002, text: 'Cashier Check' },
    CREDIT_CARD: { value: 15003, text: 'Credit Card' },
    DEBIT_CARD: { value: 15004, text: 'Debit Card' },
    MONEY_GRAM: { value: 15005, text: 'Money Gram' },
    MONEY_ORDER: { value: 15006, text: 'Money Order' },
    PERSONAL_CHECK: { value: 15007, text: 'Personal Check' },
    WESTERN_UNION: { value: 15008, text: 'Western Union' },
    WIRE: { value: 15009, text: 'Wire' },
    LOAN_TRANSFER: { value: 15010, text: 'Loan Transfer' },
    NONE: { value: 15011, text: 'None' },
    WAGE_ASSIGMENT: { value: 15012, text: 'Wage Assigment' }
  },
  LoanTypeEnum: {
    INSTALLMENT_LOAN: { value: 82010101, text: 'Installment Loan' },
    CREDIT_LOAN: { value: 82010102, text: 'Credit Line Loan' },
    MULTIPLE_ADVANCE_LOAN: { value: 82010103, text: 'Multiple Advance Loan' },
    SLAVE_LOAN: { value: 82010199, text: 'Slave Loan' }
  },
  SignStatusEnum: {
    NOT_SIGNED: { value: 0, text: 'Not Signed' },
    SIGNED: { value: 1, text: 'Signed' },
    EXPIRED: { value: 2, text: 'Expired' },
    CANCELLED: { value: 3, text: 'Canceled' },
    VOIDED: { value: 4, text: 'Voided' }
  },
  TransactionStatusEnum: {
    NONE: { value: 0, text: 'None' },
    PENDING: { value: 1, text: 'Pending' },
    NOT_CHECKED: { value: 2, text: 'Not Checked' },
    CHECKED: { value: 3, text: 'Checked' },
    REJECTED: { value: -1, text: 'Rejected' }
  },
  CategoryEnum: {
    NEW_CUSTOMER: { value: 1, text: 'New Customer' },
    RETURNING_CUSTOMER: { value: 2, text: 'Returning Customer' },
    COLLECTION_CUSTOMER: { value: 3, text: 'Collection Customer' },
    ONLINE_CUSTOMER: { value: 4, text: 'Online Program' },
    DISCARD_CUSTOMER: { value: -1, text: 'Discard Customer' },
    OTHERS: { value: 0, text: 'Others' }
  },
  PaydayOnAvailableEnum: {
    SAME_DAY: { value: 0, text: 'Same Day' },
    NEXT_BUSINESS_DAY: { value: 1, text: 'Next Business Day' }
  },
  PayrollTypeEnum: {
    DIRECT_DEPOSIT: { value: 18000, text: 'Direct Deposit' },
    PAPER_CHECK: { value: 18001, text: 'Paper Check' },
    CASH: { value: 18002, text: 'Cash' },
    UNEMPLOYED_INSURANCE: { value: 18003, text: 'Unemployed Insurance' }
  },
  WithdrawCodeEnum: {
    ACCOUNT_CLOSED: { value: 20000, text: 'Account closed' },
    BAD_EMPLOYMENT_POSITION: { value: 20001, text: 'Bad Employment/Position' },
    BAD_STATE_ZIP_CODE: { value: 20002, text: 'Bad State/ZIP Code' },
    CUSTOMER_IS_IN_COLLECTIONS: { value: 20003, text: 'Customer is in collections' },
    CUSTOMER_IS_NOT_THE_BK_ACCT_HOLDER: { value: 20004, text: 'Customer is not the bk acct holder' },
    CUSTOMER_IS_UNEMPLOYED: { value: 20005, text: 'Customer is Unemployed' },
    CUSTOMER_HAS_GARNISHMENT: { value: 20006, text: 'Customer has Garnishment' },
    CUSTOMER_HAS_LIQUID_SAVINGS_OR_BUSINESS_ACCOUNT: {
      value: 20007,
      text: 'Customer has liquid, savings or business account'
    },
    CUSTOMER_REQUESTED_NOT_TO_BE_CALLED_AGAIN: {
      value: 20008,
      text: 'Customer requested not to be called again (DNC)'
    },
    CUSTOMER_IS_DECEASED: { value: 20009, text: 'Customer is deceased' },
    FRAUD: { value: 20010, text: 'FRAUD' },
    INCORRECT_SOCIAL_SECURITY_NUMBER: { value: 20011, text: 'Incorrect Social Security Number' },
    MILITARY: { value: 20012, text: 'Military' },
    DAP_DENIED: { value: 20013, text: 'DAP denied' },
    CUSTOMER_FAILED_TO_ANSWER_THE_AUTHENTICATION_SECURITY_QUESTIONS: {
      value: 20014,
      text: 'Customer failed to answer the authentication/security questions'
    },
    CUSTOMER_HAS_HOLDS_FREEZES_RESTRICTIONS_STOP_PAYMENTS_OR_TCA_IN_THE_LAST_90_DAYS: {
      value: 20015,
      text: 'Customer has holds, freezes, restrictions, stop payments or TCA in the last 90 days'
    },
    UNABLE_TO_DO_VOB_OR_VOE: { value: 20016, text: 'Unable to do VOB or VOE' },
    SPECIAL_CASE: { value: 20017, text: 'Special Case' },
    TRIBE_DENIED: { value: 20018, text: 'Tribe Denied' },
    CUSTOMER_IS_NO_LONGER_INTERESTED: { value: 10000, text: 'Customer is no longer interested' },
    AUTO_WITHDRAWN_DUE_TO_APPLICATION_AGE: { value: 10001, text: 'Application Timeout' },
    BAD_BANK_BAD_ABA: { value: 10002, text: 'Bad Bank/Bad ABA' },
    BAD_CONTACT_INFO: { value: 10003, text: 'Bad Contact Info' },
    DUPLICATE_LEAD: { value: 10004, text: 'Duplicate lead' },
    CUSTOMER_DOES_NOT_MEET_THE_MINIMUM_REQUIRED_AGE: {
      value: 10006,
      text: 'Customer does not meet the minimum required age'
    },
    CUSTOMER_HAS_NO_DDS: { value: 10007, text: 'Customer has no DDs' },
    NOT_QUALIFIED_AS_PER_LOAN_MATRIX: { value: 10008, text: 'Not qualified as per loan matrix' },
    CUSTOMER_HAS_OPEN_LOAN_WITH_ANOTHER_CO: { value: 10009, text: 'Customer has open loan with another CO' },
    PREPAID_CARD_DEBIT_CARD: { value: 10010, text: 'Prepaid card / Debit card' },
    LANGUAGE_BARRIER: { value: 10011, text: 'Language Barrier (not Spanish nor English).' },
    UNABLE_TO_COMPLETE_CORRECTION_CUSTOMER_IS_UNREACHABLE_FOR_MORE_THAN_2_DAYS: {
      value: 10012,
      text: 'Unable to complete correction, customer is unreachable for more than 2 days'
    },
    UNPREDICTABLE_INCORRECT_PAYROLL_FREQUENCY: {
      value: 10013,
      text: 'Unpredictable/Incorrect Payroll Frequency (Monthly, Daily, Semi-monthly, Weekend)'
    },
    MINIMUM_INCOME_NOT_NET: { value: 10014, text: 'MinimumIncomeNotMet' },
    UNABLE_VER_BANK_INFORMATION: { value: 10015, text: 'UnableVerBankInfo' },
    PAPER_CHECK: { value: 10016, text: 'PaperCheck' },
    FACTOR_TRUST_FAILED: { value: 10017, text: 'FactorTrustFailed' },
    VERITEC_FAILED: { value: 10018, text: 'VeritecFailed' },
    AUTO_WITHDRAWN: { value: 10019, text: 'AutoWithdrawn' },
    OTHER: { value: 10020, text: 'Other' },
    AMOUNT_IS_TOO_LOW: { value: 10021, text: 'AmountIsTooLow' },
    NO_BUSINESS_STATE: { value: 10031, text: 'No Business State' },
    AUTO_WITHDRAWN_OF_JARVIS: { value: 30000, text: 'Auto Withdrawn' }
  },
  JsonKeyConst: {
    ABSOLUTE_TIME: 'absoluteTime',
    ACCOUNT_BALANCE: 'accountBalance',
    ACCOUNT_TYPE: 'accountType',
    ACH_PROVIDER_ID: 'achProviderId',
    ACTION: 'action',
    ADDITIONAL_FEE: 'additionalFee',
    ADDRESS: 'address',
    SIGNER_IP_ADDRESS: 'signerIpAddress',
    AMOUNT: 'amount',
    AMOUNT_OF_OPEN_LOANS: 'amountOfOpenLoans',
    APPLICATION: 'application',
    APPLICATION_ID: 'applicationId',
    APPLICATION_PROPERTY: 'applicationProperty',
    APPLICATION_NO: 'applicationNo',
    APPLICATION_STATUS: 'applicationStatus',
    APPLICATION_STATUS_TEXT: 'applicationStatusText',
    APPROVED_AMOUNT: 'approvedAmount',
    APPROVED_AT: 'approvedAt',
    APPROVED_BY: 'approvedBy',
    APPROVED_ID: 'approvedId',
    APR: 'apr',
    ASSIGNED_ID: 'assignedId',
    AUTHORIZATION_TYPE: 'authorizationType',
    AVATAR_URL: 'avatarUrl',

    BANK_ACCOUNT_BALANCE: 'bankAccountBalance',
    BANK_ACCOUNT_NO: 'bankAccountNo',
    BANK_ACCOUNT_OWNER: 'bankAccountOwner',
    BANK_ACCOUNT_TYPE: 'bankAccountType',
    BANK_FEE: 'bankFee',
    BANK_NAME: 'bankName',
    BANK_PHONE: 'bankPhone',
    BANK_ROUTING_NO: 'bankRoutingNo',
    BIRTHDAY: 'birthday',

    CATEGORY: 'category',
    CELL_PHONE: 'cellPhone',
    CHANGE_TYPE: 'changeType',
    CHARGES: 'charges',
    CITY: 'city',
    CLIENT_OFFSET: 'clientOffset',
    CODE: 'code',
    COLLECT_TIME: 'collectTime',
    COMMENTS: 'comments',
    COUNT: 'count',
    CREATE_FILE_DATE: 'createFileDate',
    CREATE_TIME: 'createTime',
    CREATED_AT: 'createdAt',
    CREDIT_DATE: 'creditDate',
    CUSTOMER_ID: 'customerId',
    CERTIFICATE_URL: 'certificateUrl',
    CONTACT_START_TIME: 'contactStartTime',
    CONTACT_STOP_TIME: 'contactStopTime',
    CHANGE_AVATAR_URL: 'changeAvatarUrl',

    DATA: 'data',
    DATA_KEY: 'dataKey',
    DAY_IN_COLLECTION: 'dayInCollection',
    DEBIT_COUNT: 'debitCount',
    DEPARTMENT_ID: 'departmentId',
    DIRECT_DEPOSIT: 'directDeposit',
    DIRECT_DEPOSITS: 'directDeposits',
    DOCUMENT_NAME: 'documentName',
    DOCUMENT_STATUS: 'documentStatus',
    DOCUMENT_TYPE: 'documentType',
    DOCUMENT_URL: 'documentUrl',
    DRIVER_LICENSE_NO: 'driverLicenseNo',
    DRIVER_LICENSE_STATE: 'driverLicenseState',

    EFFECTIVE_DATE: 'effectiveDate',
    EMAIL: 'email',
    EMPLOYER_FAX: 'employerFax',
    EMPLOYER_NAME: 'employerName',
    EMPLOYEE_NO: 'employeeNo',
    EMPLOYER_PHONE: 'employerPhone',
    EMPLOYER_PHONE_EXT: 'employerPhoneExt',
    EXPRESS: 'express',
    EVENT_DATA: 'eventData',
    EVENT_TIME: 'eventTime',
    EVENT_TYPE: 'eventType',
    EVENT_TYPE_TEXT: 'eventTypeText',
    END_OF_BALANCE: 'endOfBalance',
    END_TIME: 'endTime',
    EMPLOYER_STATE: 'employerState',
    EMPLOYER_CITY: 'employerCity',
    EMPLOYER_ZIP: 'employerZip',
    EMPLOYER_ADDRESS: 'employerAddress',

    FEATURE: 'feature',
    FURTHEST_STATUS: 'furthestStatus',
    FIELD_KEY: 'fieldKey',
    FIELD_VALUE: 'fieldValue',
    FIELD_NAME: 'fieldName',
    FIRST_DAY_OF_MONTH: 'firstDayOfMonth',
    FIRST_DAY_OF_WEEK: 'firstDayOfWeek',
    FIRST_DEBIT_DATE: 'firstDebitDate',
    FIRST_NAME: 'firstName',
    FIRST_PAYROLL_DAY: 'firstPayrollDay',
    FLAGS: 'flags',
    FOLLOW_UP_AT: 'followUpAt',
    FOLLOWUP_BY: 'followupBy',
    FOLLOWUP_ID: 'followupId',
    FOLLOWUP_TYPE: 'followupType',
    FULL_NAME: 'fullName',
    FUTURE_PAYMENT_MODE: 'futurePaymentMode',

    GENDER: 'gender',

    HOME_PHONE: 'homePhone',

    IBV: 'ibv',
    ID: 'id',
    INDICATOR: 'indicator',
    INDICATOR_CODE: 'indicatorCode',
    INTEREST: 'interest',
    INTEREST_RATE: 'interestRate',
    IS_DATE_STR: 'isDateStr',
    IS_FORCE: 'isForce',
    IS_MILITARY: 'isMilitary',
    IS_SEND_EMAIL: 'isSendEmail',
    IS_UNLOCK_OLD: 'isUnlockOld',
    INCOME_TYPE: 'incomeType',

    JOB_TYPE: 'jobType',

    KEY: 'key',

    LANGUAGE_CODE: 'languageCode',
    LANGUAGE_NAME: 'languageName',
    LAST_DEBIT_DATE: 'lastDebitDate',
    LAST_ACTION_DATE: 'lastActionDate',
    LAST_PAYMENT_DATE: 'lastPaymentDate',
    LAST_PAYDAY: 'lastPayday',
    LAST_RETURN_CODE: 'lastReturnCode',
    LAST_RETURN_DATE: 'lastReturnDate',
    LAST_NAME: 'lastName',
    LATE_FEE: 'lateFee',
    LATEST_IN: 'latestIn',
    LEAD_ID: 'leadId',
    LEFT_INTEREST: 'leftInterest',
    LEFT_LATE_FEE: 'leftLateFee',
    LEFT_NSF_FEE: 'leftNsfFee',
    LEAD_PROVIDER_ID: 'leadProviderId',
    LEAD_PROVIDER_LIST: 'leadProviderList',
    LEAD_PROVIDER_NAME: 'leadProviderName',
    LEFT_PRINCIPAL: 'leftPrincipal',
    LOAN_SIZE_AMOUNT: 'loanSizeAmount',
    LOAN_TRANSFER_AMOUNT: 'loanTransferAmount',
    LOCK_REASON: 'lockReason',
    LOCKED_AT: 'lockedAt',
    LOCKED_BY: 'lockedBy',
    LOCKED_ID: 'lockedId',
    LOAN_AGREEMENT_CODE: 'loanAgreementCode',
    LOAN_AGREEMENT_URL: 'loanAgreementUrl',
    LOAN_AMOUNT: 'loanAmount',
    LANGUAGE: 'language',

    MAXIMUM_AMOUNT: 'maximumAmount',
    MAXIMUM_LOAN_SIZE: 'maximumLoanSize',
    MESSAGE: 'message',
    MIDDLE_NAME: 'middleName',
    MILITARY: 'military',
    MINIMUM_AMOUNT: 'minimumAmount',
    MINIMUM_LOAN_SIZE: 'minimumLoanSize',
    MONTHLY_INCOME: 'monthlyIncome',

    NEW_INTEREST: 'newInterest',
    NEW_LATE_FEE: 'newLateFee',
    NEW_NSF_FEE: 'newNsfFee',
    NEW_PRINCIPAL: 'newPrincipal',
    NEXT_PAYROLL_DATE: 'nextPayrollDate',
    NOTE: 'note',
    NSF_FEE: 'nsfFee',
    NUMBER_OF_OPEN_LOANS: 'numberOfOpenLoans',
    NUMBER_OF_PAID_LOANS: 'numberOfPaidLoans',

    OPERATE_BY: 'operateBy',
    OPERATED_BY: 'operatedBy',
    OPERATED_ID: 'operatedId',
    OPERATOR: 'operator',
    OPERATOR_DATA: 'operatorData',
    OPERATOR_ID: 'operatorId',
    OPERATOR_NAME: 'operatorName',
    OPTION: 'option',
    OWNER_DATA: 'ownerData',
    OUTPUT: 'output',

    PASSWORD: 'password',
    PAYDAY_ON_AVAILABLE: 'paydayOnAvailable',
    PAYDAY_ON_HOLIDAY: 'paydayOnHoliday',
    PAYMENT_MODE: 'paymentMode',
    PAYROLL_FREQUENCY: 'payrollFrequency',
    PHASE: 'phase',
    PHONE_NUMBER: 'phoneNumber',
    PORTFOLIO: 'portfolio',
    PORTFOLIO_ID: 'portfolioId',
    PORTFOLIO_NAME: 'portfolioName',
    POSITION: 'position',
    PRINCIPAL: 'principal',
    PRIORITY: 'priority',
    PROGRAM: 'program',
    PROGRAM_ID: 'programId',
    PROGRAM_NAME: 'programName',
    PAYROLL_TYPE: 'payrollType',
    PROCEDURES: 'procedures',
    PROGRAM_TEXT: 'programText',

    REASON: 'reason',
    RECEIVE_SMS: 'receiveSms',
    RECORD_ID: 'recordId',
    REFUND_FEE: 'refundFee',
    REGULAR_AMOUNT: 'regularAmount',
    RELATIVE_TIME: 'relativeTime',
    REQUEST_RAW_DATA: 'requestRawData',
    REQUEST_TIME: 'requestTime',
    RESPONSE_RAW_DATA: 'responseRawData',
    RESPONSE_TIME: 'responseTime',
    RETURN_CODE: 'returnCode',
    RETURN_DATA: 'returnData',
    RETURN_DATE: 'returnDate',
    RETURN_DESCRIPTION: 'returnDescription',
    REVIEW_DATA_ID: 'reviewDataId',
    REFERENCE_PHONE: 'referencePhone',
    REFERENCE_FIRST_NAME: 'referenceFirstName',
    REFERENCE_LAST_NAME: 'referenceLastName',
    REFERENCE_RELATIONSHIP: 'referenceRelationship',
    ROLE_CODE: 'roleCode',

    VALIDATION_OF_EMPLOYMENT: 'validationOfEmployment',
    VALUE: 'value',
    VERBAL_AGREEMENT_ID: 'verbalAgreementId',
    VERBAL_AGREEMENT_NAME: 'verbalAgreementName',
    VERIFY_METHOD: 'verifyMethod',
    VOE: 'voe',

    SCHEDULE: 'schedule',
    SCHEDULE_ID: 'scheduleId',
    SCHEDULE_ITEM_ARRAY: 'scheduleItemArray',
    SCHEDULE_ITEM_ID: 'scheduleItemId',
    SCORE: 'score',
    PROPERTY_SECTION_ID: 'propertySectionId',
    SEARCH_INFO: 'searchInfo',
    SECTION_NAME: 'sectionName',
    SECOND_DAY_OF_MONTH: 'secondDayOfMonth',
    SECOND_PAYROLL_DAY: 'secondPayrollDay',
    SEND_BACK_BY: 'sendBackBy',
    SENT_AT: 'sentAt',
    SIGN_STATUS: 'signStatus',
    SIGNED_AT: 'signedAt',
    SIGNATURE_STATUS: 'signatureStatus',
    SOURCE: 'source',
    SOURCE_DATA: 'sourceData',
    SSN: 'ssn',
    START_TIME: 'startTime',
    STATE: 'state',
    STATUS: 'status',
    SUBMITTED_BY: 'submittedBy',
    SUBMITTED_ID: 'submittedId',
    SUMMARY: 'summary',
    SECOND_DAY_OF_WEEK: 'secondDayOfWeek',
    SUPERVISOR_PHONE: 'supervisorPhone',
    SUPERVISOR_NAME: 'supervisorName',
    SHIFT_START_TIME: 'shiftStartTime',
    SHIFT_STOP_TIME: 'shiftStopTime',
    SORT_ORDER: 'sortOrder',

    TARGET_DATA: 'targetData',
    THIRD_PARTY_FILE: 'thirdPartyFile',
    THIRD_PARTY_NAME: 'thirdPartyName',
    THIRD_PARTY_TIME: 'thirdPartyTime',
    THREAD_ID: 'threadId',
    TIME: 'time',
    TOTAL_ADDITIONAL_FEE: 'totalAdditionalFee',
    TOTAL_AMOUNT: 'totalAmount',
    TOTAL_BANK_FEE: 'totalBankFee',
    TOTAL_INTEREST: 'totalInterest',
    TOTAL_LATE_FEE: 'totalLateFee',
    TOTAL_LEFT: 'totalLeft',
    TOTAL_NSF_FEE: 'totalNsfFee',
    TOTAL_PRINCIPAL: 'totalPrincipal',
    TOTAL_PRINCIPAL_AMOUNT: 'totalPrincipalAmount',
    TOTAL_REFUND: 'totalRefund',
    TOTAL_WAIVED_ADDITIONAL_FEE: 'totalWaivedAdditionalFee',
    TOTAL_WAIVED_AMOUNT: 'totalWaivedAmount',
    TOTAL_WAIVED_INTEREST: 'totalWaivedInterest',
    TOTAL_WAIVED_LATE_FEE: 'totalWaivedLateFee',
    TOTAL_WAIVED_NSF_FEE: 'totalWaivedNsfFee',
    TOTAL_WAIVED_PRINCIPAL: 'totalWaivedPrincipal',
    TRANSACTION_DATE: 'transactionDate',
    TRANSACTION_MODE: 'transactionMode',
    TRANSACTION_NO: 'transactionNo',
    TRANSACTION_STATUS: 'transactionStatus',
    TRANSACTION_TYPE: 'transactionType',
    TELEPHONE: 'telephone',
    TOKEN: 'token',

    UNPAID_FEE: 'unpaidFee',
    UPDATE_TIME: 'updateTime',
    UPDATED_AT: 'updatedAt',

    WAIVED_ADDITIONAL_FEE: 'waivedAdditionalFee',
    WAIVED_AMOUNT: 'waivedAmount',
    WAIVED_FEE: 'waivedFee',
    WAIVED_INTEREST: 'waivedInterest',
    WAIVED_LATE_FEE: 'waivedLateFee',
    WAIVED_NSF_FEE: 'waivedNsfFee',
    WAIVED_PRINCIPAL: 'waivedPrincipal',
    WEIGHT: 'weight',
    WEST_SIDE_LENDING: 'West Side Lending',
    WITHDRAW_CODE: 'withdrawCode',

    ZIP: 'zip'
  },
  diffHideEventLog: [
    'programId',
    'lockedId',
    'lockedBy',
    'lockedAt',
    'applicationStatusText',
    'phase',
    'enteredAt',
    'submittedId',
    'submittedBy',
    'followupAt',
    'followupId',
    'followupBy',
    'approvedId',
    'approvedBy',
    'approvedAt',
    'priority'
  ],
  TotalDayOfMonthEnum: {
    LAST_B_DAY_OF_MONTH: { value: '-11', text: 'Last B-Day Of Month' },
    MID_OF_MONTH: { value: '-12', text: 'Mid Of Month' },
    FIRST_B_DAY_OF_MONTH: { value: '-13', text: 'First B-Day Of Month' },
    MINUS_ONE: { value: '-1', text: 'Last day of month' },
    ONE: { value: '1', text: '1' },
    TWO: { value: '2', text: '2' },
    THREE: { value: '3', text: '3' },
    FOUR: { value: '4', text: '4' },
    FIVE: { value: '5', text: '5' },
    SIX: { value: '6', text: '6' },
    SEVEN: { value: '7', text: '7' },
    EIGHT: { value: '8', text: '8' },
    NINE: { value: '9', text: '9' },
    TEN: { value: '10', text: '10' },
    ELEVEN: { value: '11', text: '11' },
    TWELVE: { value: '12', text: '12' },
    THIRTEEN: { value: '13', text: '13' },
    FOURTEEN: { value: '14', text: '14' },
    FIFTEEN: { value: '15', text: '15' },
    SIXTEEN: { value: '16', text: '16' },
    SEVENTEEN: { value: '17', text: '17' },
    EIGHTEEN: { value: '18', text: '18' },
    NINETEEN: { value: '19', text: '19' },
    TWENTY: { value: '20', text: '20' },
    TWENTY_ONE: { value: '21', text: '21' },
    TWENTY_TWO: { value: '22', text: '22' },
    TWENTY_THREE: { value: '23', text: '23' },
    TWENTY_FOUR: { value: '24', text: '24' },
    TWENTY_FIVE: { value: '25', text: '25' },
    TWENTY_SIX: { value: '26', text: '26' },
    TWENTY_SEVEN: { value: '27', text: '27' },
    TWENTY_EIGHT: { value: '28', text: '28' },
    TWENTY_NINE: { value: '29', text: '29' },
    THIRTY: { value: '30', text: '30' },
    THIRTY_ONE: { value: '31', text: '31' }
  },
  PrivilegeType: {
    READABLE: 4,
    WRITABLE: 2,
    EXECUTABLE: 1
  },
  PrivilegeCode: {
    WELCOME: '10000001',
    WORKBENCH: '10100001',
    APPLICATION_QUEUE: '10100002',
    APPLICATION_DETAIL: '10100003',
    CUSTOMER_SEARCH: '20010001',
    GENERATE_NEW_APPLICATION: '20110001',
    FOLLOW_UP: '20110002',
    SEND_EMAIL: '20110003',
    ADD_DOCUMENTS: '20110004',
    ADD_NOTES: '20110005',
    RFE: '20110006',
    EXPRESS: '20110007',
    TRANSFER: '20110008',
    AUTO_FORWARD_TO_UNDERWRITER_AFTER_SIGNED_LOAN_AGREEMENT: '20110009',
    DIAL: '20110010',
    DISPLAY_ACTIVE_APPLICATIONS_IN_QUEUE: '20110011',
    MANUAL_RE_CALCULATE_LOAN_AMOUNT: '20110012',
    ADJUST_APPROVED_AMOUNT: '20110013',
    CUSTOMER_OPT_IN_SETTING: '20110014',
    APPLICATION_HISTORY: '20110015',
    DOWNLOAD_FILE: '20110016',
    PERSONALLY_IDENTIFIABLE_INFORMATION: '20110017',
    SEND_TO_CUSTOMER_REVIEW: '20110018',
    SEND_TO_UNDERWRITER_REVIEW: '20110019',
    SEND_TO_TRIBE_REVIEW: '20110020',
    SEND_TO_CORRECTOR_REVIEW: '20110021',
    TRIBE_DENY_APPLICATION: '20110022',
    TRIBE_APPROVE_APPLICATION: '20110023',
    TRACKER_LIST: '20111001',
    VISIT_TREND_CHART: '20111002',
    APPROVED_NUMBER_CHART: '20111003',
    APPROVED_AMOUNT_CHART: '20111004',
    CONTRIBUTION_CHART: '20111005',
    SORT_BY_UPDATED_AT: '20112001',
    FILTER_BY_DATE_RANGE: '20112002',
    FILTER_BY_TODAY: '20112003',
    FILTER_BY_YESTERDAY: '20112004',
    FILTER_BY_7_DAYS: '20112005',
    FILTER_BY_30_DAYS: '20112006',
    GOTO_DETAIL: '20112007',
    AGENT_REVIEW_QUEUE: '20112008',
    CORRECTOR_REVIEW_QUEUE: '20112009',
    UNDERWRITER_REVIEW_QUEUE: '20112010',
    TRIBE_REVIEW_QUEUE: '20112011',
    TO_BE_SIGNED_QUEUE: '20112012',
    TO_BE_SUBMITTED_QUEUE: '20112013',
    COPY_APPLICATION: '20113001',
    LOCK_APPLICATION: '20113002',
    GRAB_APPLICATION: '20113003',
    REFRESH_ESIGN_STATUS: '20113004',
    APPLICATION_INDICATORS: '20113005',
    APPLICATION_QUALITY: '20113006',
    PERSONAL_SECTION: '20114001',
    EMPLOYMENT_SECTION: '20114002',
    BANK_INFO_SECTION: '20114003',
    BANK_RECORDS_SECTION: '20114004',
    LOAN_DETAILS_SECTION: '20114005',
    PAYMENT_SCHEDULE: '20114006',
    PAYMENT_PROGRESS: '20114007',
    DOCUMENT_FOLDER: '20114008'
  },
  COOKIE_KEY: {
    CUSTOMER_ID: 'CUSTOMER_ID',
    WSID: 'WSID',
    CATEGORY: 'CATEGORY',
    LOAN_TYPE: 'LOAN_TYPE',
    AGENT_ID: 'AGENT_ID',
    DESTINATION: 'DESTINATION',
    LOAN_ID: 'LOAN_ID',
    SEND_TIME: 'SEND_TIME',
    CODE: 'CODE',
    PERSONAL: 'PERSONAL',
    PORTFOLIO: 'PORTFOLIO',
    PORTFOLIO_ID: 'PORTFOLIO_ID',
    REQUEST_CODE: 'REQUEST_CODE',
    REQUEST_ID: 'REQUEST_ID',
    DISBURSEMENT: 'DISBURSEMENT',
    REPAYMENT: 'REPAYMENT',
    PROVIDER: 'PROVIDER'
  },
  ERR_CODE: {
    100: {
      title: 'Warm Reminder!',
      message: 'We have received your application and documents. Our underwriting department is verifying your information now. We will notify you via email as soon as we have a decision. Thank you for your patience!'
    },
    102: {
      title: 'Warm Reminder!',
      message: 'Your application is being processed. we will call you @ %customer number% if we need any information, or you can reach out to us at %portfolio number%  if you have any question.'
    },
    103: {
      title: 'Warm Reminder!',
      message: 'Your information is being reviewed. This will only take a few minutes. Please wait patiently.'
    },
    200: {
      title: 'Awesome, you made it! Your money is on the way to your account!',
      message: 'We may call you if we need any more additional information. Stay tuned, FIELD_CUSTOMER_NAME!'
    },
    201: {
      title: 'Options!',
      message: 'Your loan has been approved.'
    },
    202: {
      title: 'Warm Reminder!',
      message: 'We have received your signed loan agreement. Your application is now complete. If we need any additional information, we will call you at CUSTOMER_PHONE. If you have any questions, you can call us at PORTFOLIO_NUMBER between 9:00 am-5:00 pm EST Monday-Friday or email us at PORTFOLIO_EMAIL. We will be happy to assist you! Have a wonderful day!'
    },
    '-101': {
      title: 'Warm Reminder!',
      message: 'Our Customer Service will call you soon. Thank you. Have a wonderful day!'
    },
    '-100': {
      title: 'Welcome to PORTFOLIO_NAME.',
      message: 'Thanks for Checking in! We are processing your loan application. If you have questions, call us at PORTFOLIO_NUMBER. One of our Loan Specialists will be happy to help you!'
    },
    '-200': {
      title: 'Options!',
      message: 'Unfortunately, we cannot proceed with your application. You will receive an email with the reason for the denial in 7 business days.'
    },
    '-300': {
      title: 'Unfortunately!',
      message: 'We currently don’t have a product for active military personnel or a dependent of an active military personnel'
    },
    '-400': {
      title: 'Unfortunately!',
      message: 'We couldn‘t get a suitable lending product for you at this moment, You can check your credit score and other credit report information through this service.'
    }
  },
  IBVSelectContactType: [
    { value: 0, text: 'Send by Email' },
    { value: 1, text: 'Send by SMS' }
  ],
  IBVProviderType: {
    DECISION_LOGIC: { value: 10000, text: 'Decision Logic' },
    CHIRP: { value: 20000, text: 'Chirp' }
  },
  portfolioTokenList: [
    {
      portfolioToken: '353e9e4b-b4b2-4c9a-8611-f4b0bbf2bd2d',
      portfolioId: 10
    },
    {
      portfolioToken: '791bb4fa-7670-44ca-9c17-464cfbe43f73',
      portfolioId: 61
    },
    {
      portfolioToken: '8faebe69-f188-49e7-8aec-02fcc46530f0',
      portfolioId: 62
    }
  ],
  Portfolio: [
    { value: 10, text: 'Right Now Loan', code: 'RNL' },
    { value: 61, text: 'Post Lake Lending', code: 'PLL' },
    { value: 62, text: 'Northern Star Lending', code: 'NSL' }
  ],
  LoanActionEnum: {
    CHANGE_SIGNATURE_STATUS: { value: 2014, text: 'CHANGE_SIGNATURE_STATUS' },
    FORCE_QUIT: { value: 2021, text: 'FORCE_QUIT' }
  },
  UpdateErrorCode: {
    ZIP_CODE_AND_STATE_ERROR_CODE: { value: -83010310, text: 'ZIP_CODE_AND_STATE_ERROR_CODE' },
    EDU_EMAIL_ERROR_CODE: { value: -83010311, text: 'EDU_EMAIL_ERROR_CODE' },
    MIL_AND_GOV_EMAIL_ERROR_CODE: { value: -83010312, text: 'MIL_AND_GOV_EMAIL_ERROR_CODE' }
  }
}

export { DataType }
